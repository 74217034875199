export default class FormStateModule {
    constructor(form, formElement, submitTarget = null, updateTarget = null) {
        this.form = form;
        this.formElement = formElement;
        this.submitTarget = submitTarget;
        this.updateTarget = updateTarget;
    }

    disableForm() {
        this.form.disabled = true;
        this._setElementDisabled(true);
    }

    enableForm() {
        this.form.disabled = false;
        this._setElementDisabled(false);
    }

    _setElementDisabled(disabled) {
        const elementsToToggle = [
            'release_eye_NA',
            'release_eye_OU',
            'release_eye_OS',
            'release_eye_OD',
            'gradable_gradable',
            'gradable_partially_gradable',
            'gradable_ungradable'
        ];

        elementsToToggle.forEach(id => {
            const element = document.getElementById(id);
            if (element) {
                element.disabled = disabled;
            }
        });

        this._toggleDropzones(disabled);
        this._toggleVisibility(disabled);
    }

    _toggleDropzones(hidden) {
        const dropzoneElements = document.getElementsByClassName('dropzone');
        for (const element of dropzoneElements) {
            element.classList.toggle('hidden', hidden);
        }
    }

    _toggleVisibility(isDisabled) {
        if (this.submitTarget) {
            this.submitTarget.classList.toggle('hidden', isDisabled);
        }
        if (this.updateTarget) {
            this.updateTarget.classList.toggle('hidden', !isDisabled);
        }
    }
}
