import { Controller } from "stimulus";
import { csrfToken } from "@rails/ujs";

export default class extends Controller {
  static targets = ["toggle", "status", "backupCodes", "otpField", "submitButton", "countdown", "lockTimer"];

  connect() {
    if (this.hasToggleTarget) {
    this.updateStatus(); 
    this.toggleTarget.addEventListener("change", () => this.handleToggleChange());
  }
  if (this.hasCountdownTarget) {
    this.startCountdown(); // Start the countdown timer
  }
}

  updateStatus() {
    const status = this.toggleTarget.checked ? "Enabled" : "Disabled";
    if (this.statusTarget) {
      this.statusTarget.textContent = status;
    } else {
      console.error("Status target not found");
    }
  }

  handleToggleChange() {
    const url = `/user/${this.data.get("userId")}/update_two_factor_auth`;
    const isTwoFactorEnabled = this.toggleTarget.checked;
    const data = {
      otp_required_for_login: isTwoFactorEnabled.toString() // Convert boolean to string for params
    };
  
    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken()
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Two-factor authentication status updated");
          this.updateStatus();
          
          // Set the message based on whether 2FA is enabled or disabled
          const message = isTwoFactorEnabled ? '2FA Successfully Enabled' : '2FA Successfully Disabled';
          
          Swal.fire({
            icon: 'success',
            title: message,
            showConfirmButton: false,
            timer: 1000
          });
          location.reload();
        } else {
          console.error("Error updating status:", data.errors);
        }
      })
      .catch(error => console.error("Error:", error));
  }
  

  copyToClipboard(event) {
    event.preventDefault(); 
  
    if (!this.hasBackupCodesTarget) {
      console.error("Backup codes target not found");
      return;
    }
  
    const backupCodes = this.backupCodesTarget.innerText;
    navigator.clipboard.writeText(backupCodes).then(() => {
      // Show a message indicating success
      const successMessage = document.createElement('div');
      successMessage.textContent = 'Backup codes copied!';
      successMessage.classList.add('text-green-600', 'mt-2');
      this.element.appendChild(successMessage);
  
      setTimeout(() => {
        successMessage.remove();
      }, 3000);
      
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }
  startCountdown() {
    const countdownElement = this.countdownTarget;
    let timeRemaining = parseInt(countdownElement.innerText);
  
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        timeRemaining -= 1;
        countdownElement.innerText = timeRemaining;
      } else {
        const parentElement = countdownElement.parentElement;
        parentElement.innerText = "You can try now.";
        parentElement.classList.remove("text-red-500");  
        parentElement.classList.add("text-green-500");   
  
        clearInterval(interval);
        this.otpFieldTarget.disabled = false;
        this.submitButtonTarget.disabled = false;
      }
    }, 1000);
  }
  

}
