import { Controller } from 'stimulus';
import { Formio } from 'formiojs';

import Hammer from 'hammerjs';
import * as cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';

import * as cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import BaseFileModule from './shared_modules/base_file_module.js';
import FormStateModule from './shared_modules/form_state_module.js';
import NotificationModule from './shared_modules/notification_module.js';
import FormSubmissionModule from './shared_modules/form_submission_module.js';

cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
cornerstoneWADOImageLoader.configure({
    beforeSend: function (xhr) {

        // Add custom headers here (e.g. auth tokens)
        //xhr.setRequestHeader('x-auth-token', 'my auth token');
    },
    useWebWorkers: true,
});

const {
    PanTool,
    WwwcTool,
    ZoomMouseWheelTool,
    LengthTool,
    ArrowAnnotateTool,
    AngleTool,
    EllipticalRoiTool,
    EraserTool,
    RectangleRoiTool,
    FreehandRoiTool,
    StackScrollMouseWheelTool
} = cornerstoneTools;

const LEFT_CLICK = 1;
const MIDDLE_CLICK = 4;
// const RIGHT_CLICK = 2;

export default class extends Controller {
    static targets = ['viewer', 'url', 'form', 'path', 'agreement', 'imageTag', 'submit', 'update', 'menu', 'cornerstone', 'formSection', 'menuIcon', 'cornerstoneIcon', 'questionArea']

    async initialize() {
        // Define Targets
        const elem = this.viewerTarget;
        this.imagesMenu = this.menuTarget;
        this.cornerstoneImages = this.cornerstoneTarget;
        this.formSection = this.formSectionTarget;
        this.EligibilityFormUserId = this.data.get('userId');
        this.eligibilityFormElem = this.formTarget;
        this.EligibilityFormAgreementElem = this.agreementTarget;
        this.imageTag = this.imageTagTarget;
        this.gradingFormStackId = this.data.get('stackId');
        this.imageTagList = [{ "label": "Study Date", "hex": 'x00080020' },
        { "label": "Study Time", "hex": 'x00080030' },
        { "label": "Operators Name", "hex": 'x00081070' },
        { "label": "Manufacturer's Model Name", "hex": 'x00081090' },
        { "label": "Patient ID", "hex": 'x00100020' },
        { "label": "Device Serial Number", "hex": 'x00181000' },
        { "label": "Software Version(s)", "hex": 'x00181020' },
        { "label": "Image Laterality", "hex": 'x00200062' }]
        this.viewerElement = elem;
        const imageKeys = this.data.get('imageKeys');
        this.eligibilityAnnotationSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        if (imageKeys && imageKeys.trim() !== '[]') {
            // Split the string by commas and remove any leading/trailing spaces, then filter out empty strings
            this.gradingImages = imageKeys.split(',').map(key => key.trim()).filter(Boolean);
        } else {
            // If imageKeys is not provided or is empty/contains only [], set this.gradingImages to an empty array
            this.gradingImages = [];
        }
        this.images = [];
        this.readImages();
        this.currentImage = 0;
        this.eligibilityFormVersionId = this.data.get('versionId');
        // load previously saved grading data if available
        this.annotation = JSON.parse(this.data.get('annotation'));
        this.annotationIsLoadedOnce = {};
        this.eligibilityFormSchema = JSON.parse(this.data.get('eligibilityForm'));
        this.eligibilityFormResponses = JSON.parse(this.data.get('responses'));
        this.eligibilitySaveUrl = document.location.origin + this.data.get('eligibilityFormUpdatePath') + '.json';
        this.eligibilitystudyId = this.data.get('studyId');
        // Sample URL
        const url = this.eligibilitySaveUrl
        // Split the URL by '/'
        const urlParts = url.split('/');
        // Extract the stack id from the URL
        const stack = urlParts[urlParts.indexOf('stacks') + 1];
        this.stackId = stack

        // renderer
        this.eligibilityForm = await Formio.createForm(this.eligibilityFormElem, this.eligibilityFormSchema, {
            noAlerts: true
        });
        // set the draft responses if any
        this.eligibilityForm.submission = this.eligibilityFormResponses;

        this.formSaveDraftStatus = false;

        const displayImagesForQuestions = (formComponents, eligibilityFormResponses, ImagesArray) => {
            // Split the string into an array of URLs using a comma as the separator
            const imagesArray = ImagesArray.split('<==>').map(url => url.trim());
            // Iterate through the form components
            FormioUtils.eachComponent(formComponents, component => {
                // Check if the component key is in eligibilityFormResponses
                if (component.key in eligibilityFormResponses.data) {
                    const questionKey = component.key;
                    const verified_key = questionKey + "_sdv_verified";
                    const comments_key = questionKey + "_sdv_comments";
                    if (eligibilityFormResponses.sdv) {
                        if (verified_key in eligibilityFormResponses.sdv) {
                            const check_sdv = eligibilityFormResponses.sdv[verified_key];
                            const sdv_comments = eligibilityFormResponses.sdv[comments_key];
                            this.appendSdvWithQuestionTitles(component, check_sdv, sdv_comments);
                        }
                    }
                    if (eligibilityFormResponses && eligibilityFormResponses.files && questionKey in eligibilityFormResponses.files) {
                        const imageUrls = eligibilityFormResponses.files[questionKey];
                        this.appendImageWithQuestion(component, imageUrls, imagesArray)
                    }
                }
            });
        }

        // Call the function with your data
        Formio.createForm(this.eligibilityFormElem, 'https://examples.form.io/example').then((form) => {
            this.eligibilityForm.on("render", () => {
                if (Object.keys(this.eligibilityFormResponses).length > 0) {
                    displayImagesForQuestions(this.eligibilityForm.components, this.eligibilityFormResponses, this.data.get('attachments'));
                }
            });
            // uncomment the line below to rerender
            this.eligibilityForm.redraw();
            if (this.eligibilityForm.disabled) {
                const dropzoneElements = document.getElementsByClassName('dropzone');
                for (const element of dropzoneElements) {
                    element.classList.add('hidden');
                }
            }
        });
        /**
         *
         * Corner Stone Setup
         */
        cornerstoneWebImageLoader.external.cornerstone = cornerstone;
        cornerstoneTools.external.cornerstone = cornerstone;
        cornerstoneTools.external.Hammer = Hammer;
        cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
        cornerstoneTools.init({
            mouseEnabled: true,
            touchEnabled: true,
            globalToolSyncEnabled: true,
            showSVGCursors: true

        });

        cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
            (element, imageId, error) => {
                console.error(imageId);
                // throw error;
            }
        );

        cornerstone.enable(elem);
        this.imageLoader(this.url);
        // attach event listeners
        this.viewerElement.addEventListener('cornerstonenewimage', (e) => {
            // console.log(e);
            // By resetting viewer the tooldata is not affected
            cornerstone.reset(this.viewerElement)
            e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
            e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
        });
        await this.checkifAllImagesUploaded();

        // Initialize shared modules
        this.baseFile = new BaseFileModule(this.element);
        this.formState = new FormStateModule(this.eligibilityForm, this.formTarget, this.hasSubmitTarget ? this.submitTarget : null, this.hasUpdateTarget ? this.updateTarget : null);
        this.notifications = new NotificationModule(this.hasLoadingImageTarget ? this.loadingImageTarget : null);

        this.formSubmissionModule = new FormSubmissionModule({
            formSaveUrl: document.location.origin + this.data.get('eligibilityFormUpdatePath') + '.json',
            formVersionId: this.data.get('versionId'),
            formUserId: this.data.get('userId'),
            formStackId: this.data.has('stackId') ? this.data.get('stackId') : null,
            notifications: this.notifications,
            stackIsFinalUrl: '',
            images: this.gradingImages
        });
        // Set the form in modules
        this.baseFile.setForm(this.eligibilityForm);
        if (this.EligibilityFormAgreementElem.checked) {
            this.formState.disableForm();
        } else {
            this.formState.enableForm();
        }
    }

    checkifAllImagesUploaded() {
        this.eligibilityForm.on("change", () => {
            const qcImagesElements = document.querySelectorAll('.qc_images');
            this.dataQuestionArray = Array.from(qcImagesElements).map(element => element.getAttribute('data-question'));
        })
    }

    addCornerstoneToolsAndAnnotations(imageUrl) {

        // Activate Pan Tool on Middle Click Drag
        cornerstoneTools.addTool(PanTool);
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: MIDDLE_CLICK });

        // Activate WWWc tool on Left Click Drag
        cornerstoneTools.addTool(WwwcTool);
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });

        // Activate Zoom Mouse Wheel Tool
        cornerstoneTools.addTool(ZoomMouseWheelTool, {
            // Optional configuration
            configuration: {
                invert: false,
                preventZoomOutsideImage: false,
                minScale: 0.01,
                maxScale: 20.0
            }
        });
        cornerstoneTools.setToolActive('ZoomMouseWheel', {});
        // Add Non Active tools
        cornerstoneTools.addTool(LengthTool);
        cornerstoneTools.addTool(ArrowAnnotateTool);
        cornerstoneTools.addTool(AngleTool);
        cornerstoneTools.addTool(RectangleRoiTool);
        cornerstoneTools.addTool(FreehandRoiTool);
        cornerstoneTools.addTool(EraserTool);
        cornerstoneTools.addTool(cornerstoneTools.ProbeTool);
        cornerstoneTools.addTool(EllipticalRoiTool, {
            configuration: {
                drawHandlesOnHover: false,
                hideHandlesIfMoving: true,
                renderDashed: false
            }
        });
        // register cornerstone tools events
        // https://github.com/cornerstonejs/cornerstoneTools/blob/master/src/events.js
        this.viewerElement.addEventListener('cornerstonetoolsmeasurementcompleted', e => {
            console.log(`------${e.detail.toolName}-----`);
            console.table(e.detail.measurementData);
        });
        this.refreshTools();
    }

    imageLoader(imageUrl) {
        imageUrl = document.location.origin + '/' + imageUrl;
        this.imageTag.innerHTML = "";
        if (imageUrl) {

            let imageIds = [];
            let imageIdRoot = 'wadouri:' + imageUrl;
            let stack = {};
            // http:
            if (imageUrl.includes('.dcm')) {
                cornerstoneWADOImageLoader.wadouri.dataSetCacheManager.load(imageUrl, cornerstoneWADOImageLoader.internal.xhrRequest).then((dataSet) => {
                    // dataset is now loaded, get the # of frames so we can build the array of imageIds
                    //(0028,0008)	IS	Number of Frames is the tag that defines the number of frames as per dicomliberary which means this will not change
                    let numFrames = dataSet.intString('x00280008');
                    this.imageTagList.forEach(t => {
                        if (dataSet.intString(t.hex)) {
                            this.imageTag.innerHTML += `<div>${t.label} : ${dataSet.intString(t.hex)}</div>`;
                        }
                    })
                    if (!numFrames) {
                        imageIds.push(imageIdRoot);
                    } else {
                        for (var i = 0; i < numFrames; i++) {
                            imageIds.push(imageIdRoot + "?frame=" + i);
                        }
                    }
                    stack = {
                        currentImageIdIndex: 0,
                        imageIds: imageIds
                    };
                    if (imageIds.length > 2) {
                        cornerstone.loadAndCacheImage(imageIds[0]).then((image) => {
                            // now that we have an image frame in the cornerstone cache, we can decrement
                            // the reference count added by load() above when we loaded the metadata.  This way
                            // cornerstone will free all memory once all imageId's are removed from the cache
                            cornerstone.displayImage(this.viewerElement, image);
                            cornerstoneTools.addStackStateManager(this.viewerElement, ["stack"]);
                            cornerstoneTools.addToolState(this.viewerElement, "stack", stack);
                            stack.imageIds.forEach((imageId) => {
                                this.loadExistingAnnotationsForImage(imageId);
                            });
                            this.addCornerstoneToolsAndAnnotations(imageIds[0]);
                            cornerstoneTools.addTool(StackScrollMouseWheelTool);
                            cornerstoneTools.setToolActive('StackScrollMouseWheel', {});
                        }, function (err) {
                            console.log('loadAndCacheImage error', err);
                        });
                    }
                    else {
                        cornerstone.loadAndCacheImage(imageUrl).then((image) => {
                            this.addCornerstoneToolsAndAnnotations(imageUrl);
                        }, function (err) {
                            console.log('loadImage error', err);
                        });
                    }
                });
            }

        }
    }

    loadExistingAnnotationsForImage(url) {
        if (this.annotation[url] && !this.annotationIsLoadedOnce[url]) {
            cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(url, this.annotation[url]);
            this.annotationIsLoadedOnce[url] = true;
            this.refreshTools();
        }
    }

    refreshTools() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        tools.forEach(tool => {
            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
        });
    }

    readImages() {
        const images = document.querySelectorAll('.image');
        images.forEach(i => {
            this.images.push({
                id: i.getAttribute('data-id'),
                url: i.getAttribute('data-url'),
                elem: i
            });
        });
        // console.log(this.images);
    }

    loadImage(url, id) {
        url = document.location.origin + url;
        cornerstone.loadAndCacheImage(url).then((image) => {
            this.loadExistingAnnotationsForImage(url);
            cornerstone.displayImage(this.viewerElement, image);
            cornerstone.enable(this.viewerElement);
        });
    }

    changeImage(e, elem) {
        let target;
        if (e) target = e.target;
        else target = elem;
        // load new image
        this.imageLoader(target.dataset.url);

        // if the call came from click adjust the currentImage index
        if (e) {
            this.currentImage = target.dataset.idx;
            console.log(`current index seleted ${this.currentImage}`);
        }
        // remove all active borders
        const allImageBoxes = document.querySelectorAll('.image-box');
        allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));

        // active border around current image
        target.parentNode.classList.add('border-primary-500');
    }

    nextImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + 1) % len);
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    previousImage(e) {
        console.log(`current index: ${this.currentImage}`);
        const len = this.images.length;
        this.currentImage = Math.abs((this.currentImage + len - 1)) % len;
        console.log(` after: ${this.currentImage}`);
        this.changeImage(null, this.images[this.currentImage].elem);
        e.preventDefault();
    }

    toggleTags(e) {
        this.imageTag.classList.toggle("hidden");
        e.preventDefault();
    }

    labelTool(e) {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];

        tools.forEach(tool => {
            cornerstoneTools.setToolEnabled(tool, { mouseButtonMask: LEFT_CLICK });
        });

        this.viewerElement.addEventListener('cornerstonetoolsmousedown', e => {
            const coord = e.detail.currentPoints.canvas;
            const mouseBtn = e.detail.buttons;
            // if its a right click
            if (mouseBtn === 2) {
                tools.forEach(tool => {
                    const state = cornerstoneTools.getToolState(this.viewerElement, tool);
                    if (state) {
                        state.data.forEach(d => {
                            const isNear = cornerstoneTools.getToolForElement(this.viewerElement, tool).__proto__.pointNearTool(this.viewerElement, d, coord);
                            if (isNear) console.log(`${tool} with ${d.uuid} near : ${isNear}`);
                            cornerstoneTools.setToolActive(tool, { mouseButtonMask: LEFT_CLICK });
                            // when near is detected see if we can have the annotation highlited green
                        });
                    }
                });
            }
        });
        e.preventDefault();
    }

    levelsTool(e) {
        cornerstoneTools.setToolActive('Wwwc', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    panTool(e) {
        cornerstoneTools.setToolActive('Pan', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    lengthTool(e) {
        cornerstoneTools.setToolActive('Length', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    arrowAnnotationTool(e) {
        cornerstoneTools.setToolActive('ArrowAnnotate', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    angleTool(e) {
        cornerstoneTools.setToolActive('Angle', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    rectangleTool(e) {
        cornerstoneTools.setToolActive('RectangleRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    freehandTool(e) {
        cornerstoneTools.setToolActive('FreehandRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    ellipseTool(e) {
        cornerstoneTools.setToolActive('EllipticalRoi', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    eraserTool(e) {
        cornerstoneTools.setToolActive('Eraser', { mouseButtonMask: LEFT_CLICK });
        e.preventDefault();
    }

    submitGrading(e) {

        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
        this.saveGrading(annotations);
        e.preventDefault();
    }

    saveGrading(annotations) {
        const method = this.data.get('mode') == "update"
            ? "PATCH" : "POST";
        fetch(this.eligibilityAnnotationSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: method,
            body: JSON.stringify({
                eligibility: {
                    comment: 'Update',
                    annotations: annotations,
                    user_id: this.EligibilityFormUserId,
                    stack_id: this.gradingFormStackId
                }
            })
        }).then(response => {
            console.log(response);
        });
    }

    get url() {
        return this.data.get('url');
    }

    // formio functions
    formSaveDraft(e) {
        this.handleFormCommitAndSubmit(e, 'initiated', true, true);
    }

    formCommit(e) {
        if (this.EligibilityFormAgreementElem.checked) {
            this.handleFormCommitAndSubmit(e, 'completed', true);
        } else {
            alert('You must agree to terms');
        }
        e.preventDefault();
    }

    formUpdate(e) {
        this.handleFormCommitAndSubmit(e, 'editing');
    }

    handleFormCommitAndSubmit(e, status, shouldSubmit = false, draftStatus = false) {
        e.preventDefault();

        // Validate the form before submission
        this.eligibilityForm.submit().then((submission) => {
            // Check if images are uploaded!
            if (status == 'completed') {
                const uploaded = this.formSubmissionModule.checkIfAllImagesUploaded(submission, this.dataQuestionArray);

                if (!uploaded) {
                    // Reject the promise to prevent further execution
                    this.notifications.error('Images not uploaded');
                    return;
                }
            }
            // Assuming you have this.gradingImages defined in your controller
            this.formSubmissionModule.collectImagesFromSelector('.attached_images', this.gradingImages);
            this.defaultSaveAnnotation();
            // Handle the form submission after commit is successful
            return this.formSubmissionModule.submitForm(
                submission,
                this.data.get('mode') == "update" ? "PATCH" : "POST",
                null,
                null,
                draftStatus,
                'eligibility_form',
                status
            );
        }).catch((error) => {
            console.error(error);
            // You can also handle any additional error logic here if needed
        });
    }

    defaultSaveAnnotation() {
        const tools = ['Length', 'ArrowAnnotate', 'Angle', 'EllipticalRoi', 'RectangleRoi', 'FreehandRoi'];
        // const annotations = {};

        const annotations = cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

        this.saveGrading({ ...annotations, ...this.annotation });
    }

    toggleImage() {
        if (this.cornerstoneImages.dataset.expanded === "1") {
            this.collapse_cornerstone()
        } else {
            this.expand_cornerstone()
        }
    }

    collapse_cornerstone() {

        const grading_tools = this.cornerstoneImages.querySelector('.grading_tools');
        this.cornerstoneImages.classList.add('col-span-1')
        this.cornerstoneImages.classList.remove('col-span-6')
        grading_tools.classList.add('hidden');
        this.viewerTarget.classList.add('hidden');
        this.cornerstoneImages.dataset.expanded = "0";
        this.cornerstoneIconTarget.innerHTML = `VIEWER`;
        this.cornerstoneIconTarget.classList.add('text-white');
        this.cornerstoneIconTarget.classList.add('p-1');
        this.cornerstoneIconTarget.classList.remove('float-right');
        this.largeForm()
    }

    expand_cornerstone() {
        const grading_tools = this.cornerstoneImages.querySelector('.grading_tools');
        this.cornerstoneImages.classList.add('col-span-6')
        this.cornerstoneImages.classList.remove('col-span-1')
        grading_tools.classList.remove('hidden');
        this.viewerTarget.classList.remove('hidden');
        this.cornerstoneImages.dataset.expanded = "1";
        this.cornerstoneIconTarget.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
        `;
        this.cornerstoneIconTarget.classList.remove('text-white');
        this.cornerstoneIconTarget.classList.remove('p-1');
        this.cornerstoneIconTarget.classList.add('float-right');
        this.reverseFormChanges()
    }

    largeForm() {
        this.formSection.classList.add('col-span-10');
        this.formSection.classList.remove('col-span-5');
    }

    reverseFormChanges() {
        this.formSection.classList.add('col-span-5');
        this.formSection.classList.remove('col-span-10');
    }

    toggleSubmitArea() {
        var staticPanel = document.getElementById('expend_colapse');
        if (this.questionAreaTarget.classList.contains('hidden')) {
            this.questionAreaTarget.classList.remove('hidden');
            staticPanel.classList.add('fa-minus-square-o');
            staticPanel.classList.remove('fa-plus-square-o');
        } else {
            this.questionAreaTarget.classList.add('hidden');
            staticPanel.classList.add('fa-plus-square-o');
            staticPanel.classList.remove('fa-minus-square-o');
        }
    }

    hideLoader() {
        this.loadingImageTarget.classList.remove("visible");
    }

    appendImageWithQuestion(component, imageUrls, imagesArray) {
        const addedImages = new Set();
        // Create a div to hold the images
        const imageDiv = document.createElement('div');
        imageDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-300', 'flex', 'gap-2');
        // Iterate through the image URLs and create image elements
        for (const imageUrl of imageUrls) {
            for (let i = 0; i < imagesArray.length; i++) {
                let imageUrlParts = imagesArray[i].split('/');
                let imageName = imageUrlParts[imageUrlParts.length - 1];
                // Decode the URL containing %20
                let decodedImageUrl = decodeURIComponent(imageName);
                if (imageUrl == decodedImageUrl && !addedImages.has(decodedImageUrl)) {
                    const imageElement = document.createElement('img');
                    imageElement.setAttribute('src', imagesArray[i]);
                    imageElement.setAttribute('alt', 'Attachment Image');
                    imageElement.setAttribute('width', '100');
                    imageElement.setAttribute('height', '100');
                    imageElement.style.cursor = 'pointer';
                    // Create the delete icon using an SVG image
                    const deleteIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
                    deleteIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
                    deleteIcon.setAttribute('width', '20');
                    deleteIcon.setAttribute('height', '20');
                    deleteIcon.setAttribute('viewBox', '0 0 24 24');
                    deleteIcon.style.cursor = 'pointer';
                    deleteIcon.setAttribute('id', 'deleteIconId');
                    if (this.eligibilityForm._disabled == false) {
                        // Add SVG path for the red bin icon
                        deleteIcon.innerHTML = `
                        <title>Delete Attachment</title>
                        <path fill="#FF0000" d="M18 4h-4.18l-1.82-2H10L8.82 4H4v2h16V4zM6.36 8h11.28l1.23 10.96l.01.04V20H4v-1.96l.01-.04L5.64 8zM8 18h8v-2H8v2zm0-4h8v-6H8v6z"/>
                        `;
                    }
                    const stackId = this.gradingFormStackId;
                    deleteIcon.addEventListener('click', () => {
                        // Display a confirmation dialog
                        const confirmation = confirm('Are you sure you want to delete this image?');
                        if (confirmation) {
                            imageElement.parentElement.remove();
                            addedImages.delete(decodedImageUrl);
                            // Get the image URL associated with the clicked delete icon
                            const imageUrlToDelete = imageElement.src;
                            const fileSegments = imageUrlToDelete.split('/');
                            const fileName = fileSegments[fileSegments.length - 1];
                            // Send a DELETE request to the server to delete the image
                            fetch(`/delete_image`, {
                                method: 'post',
                                headers: {
                                    'Content-Type': 'application/json; charset=utf-8',
                                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                                },
                                body: JSON.stringify({
                                    image_url: fileName,
                                    stack_id: stackId,
                                    image_array: component.key
                                })
                            })
                                .then(response => {
                                    if (response.ok) {
                                        // Image deleted successfully from storage
                                        console.log('Image deleted successfully');
                                        // Remove the corresponding image element from the DOM
                                        window.location.reload();
                                    } else {
                                        // Failed to delete image
                                        console.error('Failed to delete image');
                                    }
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                });
                        }
                    });
                    // Create a container div for the image and delete icon
                    const imageContainer = document.createElement('div');
                    imageContainer.classList.add('image-container');
                    imageContainer.appendChild(imageElement);
                    imageContainer.appendChild(deleteIcon);
                    imageElement.addEventListener('click', function () {
                        const lightboxImage = document.getElementById('lightbox-image');
                        lightboxImage.src = imagesArray[i];
                        const lightboxContainer = document.getElementById('lightbox-container');
                        lightboxContainer.style.display = 'block';
                    });
                    const lightboxContainer = document.getElementById('lightbox-container');
                    lightboxContainer.addEventListener('click', function () {
                        lightboxContainer.style.display = 'none';
                    });
                    // Append the image to the div
                    imageDiv.appendChild(imageContainer);
                    addedImages.add(decodedImageUrl);
                }
            }
        }
        // Append the image div to the question component's element
        if (imageDiv.innerHTML.trim() != "") {
            component.element.appendChild(imageDiv);
        }
    }

    appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {

        if (check_sdv === 'true') {
            // Create a span element for the SVG icon with the tooltip
            const svgDive = document.createElement('span');
            svgDive.classList.add('has-tooltip');
            svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
            // Get the label element within component.element
            const labelElement = component.element.querySelector('label');
            if (labelElement) {
                labelElement.classList.add('flex', 'gap-2');
                // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
                labelElement.innerHTML = component.label + svgDive.outerHTML;
            } else {
                console.error('Label element not found.');
            }
        }
    }

    printForm() {
        // if (this.graderResponses && this.graderResponses.length > 0) {
        this.getFormData(this.gradingFormStackId)
            .then(response => {
                // Extract eligibility_details from the response
                const eligibilityDetails = response.eligibility_details;

                // Create a table element
                const customTable = document.createElement('table');
                customTable.classList.add('custom-table');

                // Define headers and corresponding data keys
                const headerDataPairs = [
                    { header: 'Study Name', key: 'study_name' },
                    { header: 'Site Name', key: 'site_name' },
                    { header: 'Site Number', key: 'site_number' },
                    { header: 'OIRRC Site Code', key: 'oirrc_site_code' },
                    { header: 'Subject Code', key: 'subject_code' },
                    { header: 'Screened Eye(s)', key: 'enrolled_eye' },
                    { header: 'Visit Name', key: 'visit_name' },
                    { header: 'Visit Date', key: 'visit_date' },
                ];

                // Insert headers and corresponding data into the table
                for (let i = 0; i < headerDataPairs.length; i += 2) {
                    // Create table row
                    const row = customTable.insertRow();

                    // Insert cells for two columns or fewer if reaching the end
                    for (let j = i; j < i + 2 && j < headerDataPairs.length; j++) {
                        const pair = headerDataPairs[j];
                        // Create cell for header
                        const headerCell = row.insertCell();
                        headerCell.textContent = pair.header;
                        headerCell.style.fontWeight = 'bold'; // Set header text to bold
                        headerCell.style.borderBottom = '1px solid #ddd';

                        // Create cell for corresponding data
                        const dataCell = row.insertCell();
                        dataCell.textContent = eligibilityDetails[pair.key];
                        dataCell.style.borderBottom = '1px solid #ddd';
                    }
                    // Insert an empty row between pairs, except for the last pair
                    if (i + 2 < headerDataPairs.length) {
                        customTable.insertRow().innerHTML = '<td colspan="2" style="height: 20px;"></td>';
                    }
                }

                // Create a custom header element for study details
                const studyDetails = document.createElement('div');
                studyDetails.classList.add('custom-header');
                studyDetails.textContent = 'Study And Subject Details';
                studyDetails.style.padding = '10px';
                studyDetails.style.textAlign = 'center';
                studyDetails.style.fontWeight = 'bold';
                studyDetails.style.marginBottom = '0px';
                studyDetails.style.fontSize = '22px';

                // Create a wrapper element
                const wrapper = document.createElement('div');
                wrapper.style.textAlign = 'center'; // Center align all elements
                wrapper.style.marginBottom = '5px'; // Space between elements

                // Create a logo element
                const logo = document.createElement('img');
                const basePath = document.querySelector('meta[name="asset-base-path"]').getAttribute('content');
                logo.src = basePath;
                logo.style.display = 'block';
                logo.style.margin = '0 auto 10px auto';
                logo.style.width = '250px'; // Set the desired width
                logo.style.height = 'auto'; // Maintain aspect ratio

                // Set up the onload event to append elements only after the logo is fully loaded
                logo.onload = () => {

                    // Append elements to the wrapper
                    wrapper.appendChild(logo);
                    wrapper.appendChild(studyDetails);

                    // Create a space between the header and the table
                    const headerSpace = document.createElement('div');
                    headerSpace.style.height = '20px'; // Adjust the height as needed
                    wrapper.appendChild(headerSpace);
                    wrapper.appendChild(customTable);
                    // Apply CSS to style the table
                    customTable.style.width = '100%'; // Adjust as needed
                    customTable.style.borderCollapse = 'collapse'; // Optional

                    // Prepend the wrapper to the document body
                    document.body.prepend(wrapper);

                    // Remove elements to hide before printing
                    const elementsToRemove = [
                        {
                            class: '.printhide',
                            elements: document.querySelectorAll('.printhide')
                        },
                        {
                            class: '.printhidenav',
                            elements: document.querySelectorAll('.printhidenav')
                        },
                        {
                            class: '.printfoothide',
                            elements: document.querySelectorAll('.printfoothide')
                        },
                        {
                            class: '.printsidehide',
                            elements: document.querySelectorAll('.printsidehide')
                        },
                        // Add more objects for other classes as needed
                    ];

                    elementsToRemove.forEach(obj => {
                        obj.elements.forEach(element => {
                            element.remove();
                        });
                    });

                    // Trigger the browser's print functionality
                    window.print();

                    // Remove the wrapper after printing
                    wrapper.remove();
                };

                // Handle image load errors
                logo.onerror = () => {
                    console.error('Failed to load the logo');
                };

            })
            .catch(error => {
                console.error(error);
                // Handle errors if any
            });
        // }
    }

    getFormData(stackId) {
        const url = `${document.location.origin}/studies/${this.eligibilitystudyId}/eligibility_forms/get_eligibility_form_details`;
        return fetch(url, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'POST',
            body: JSON.stringify({
                stack_id: stackId
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                // Parse JSON response
                return response.json();
            })
            .then(data => {
                // Handle the data received from the server
                return data; // Return the data if necessary
            })
            .catch(error => {
                console.error(error);
                // Handle errors if any
            });
    }

}
