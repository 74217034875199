// base_file_module.js
export default class BaseFileModule {
    constructor(element) {
        this.element = element;
        this.form = null; // Initialize form to null
        this.connect();
    }

    connect() {
        this.element.addEventListener("successFileUpload", this.handleFileUpload.bind(this));
        this.element.addEventListener("removeFile", this.handleFileRemove.bind(this));
    }

    handleFileUpload(event) {
        const form = this.getForm();
        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;

        if (!form.submission) {
            form.submission = {}; // Initialize submission if it doesn't exist
        }

        if (!form.submission.files) {
            form.submission.files = {}; // Initialize files if it doesn't exist
        }

        if (!form.submission.files.hasOwnProperty(questionName)) {
            form.submission.files[questionName] = [];
        }

        form.submission.files[questionName].push(fileName);
    }

    handleFileRemove(event) {
        const form = this.getForm();
        const fileName = event.detail.fileName;
        const questionName = event.detail.questionName;

        if (form.submission && form.submission.files && form.submission.files.hasOwnProperty(questionName)) {
            const fileArray = form.submission.files[questionName];
            const index = fileArray.indexOf(fileName);

            if (index !== -1) {
                fileArray.splice(index, 1);

                if (fileArray.length === 0) {
                    delete form.submission.files[questionName];
                }
            }
        }
    }

    getForm() {
        if (!this.form) {
            throw new Error("Form is not set. Use setForm() to set the form.");
        }
        return this.form;
    }

    setForm(form) {
        this.form = form;
    }
}