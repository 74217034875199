import { Controller } from 'stimulus';

import $ from 'jquery';
require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    static targets = ['form','selectedformtype','selectedModalityValue']

    connect() {
        $('.visit-search').select2();
        $('.select2-container--default .select2-selection--multiple').css({
            'min-height': '42px',
            'border-color': '#cbd5e0'
        });
    }

    dependentSelect() {
        const selectedformtype = $(this.selectedformtypeTarget);
        const selectedformtypeText = selectedformtype.find(':selected').text();
        const dependDropdown = document.getElementById('dependDropdown');
        const formVersionSelect = document.getElementById('data_export_form_version');
        if (selectedformtypeText === "grading" || selectedformtypeText === "Final") {
            dependDropdown.classList.remove('hidden');
            formVersionSelect.setAttribute('required', true);
        }else{
            dependDropdown.classList.add('hidden');
            formVersionSelect.removeAttribute('required');
        }
    }
    selectModalityForms(){
        const selectedModalityValue = $(this.selectedModalityValueTarget);
        const modality_id = selectedModalityValue.val();
        const studyId = document.getElementById('data_export_study_id').value;
        const Url = document.location.origin + `/studies/${studyId}/data_exports/get_grading_form`
    fetch(Url, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: 'POST',
        body: JSON.stringify({
            modality_id: modality_id
        })
    }).then(response => response.json())
    .then(data => {
        const formVersionSelect = document.getElementById('data_export_form_version');
        formVersionSelect.innerHTML = '';
    
        if (data && data.length > 0) {
            const ids = data.map(item => item.id);
            const names = data.map(item => item.form_title);
    
            const defaultOption = document.createElement('option');
            defaultOption.value = "";
            defaultOption.text = "Select Grading Form";
            formVersionSelect.appendChild(defaultOption);
    
            if (data.length === 1) {
                defaultOption.value = ids[0];
                defaultOption.text = names[0];
                defaultOption.selected = true;
                const dependDropdown = document.getElementById('dependDropdown');
                dependDropdown.classList.add('pointer-events-none');
            } else {
                const dependDropdown = document.getElementById('dependDropdown');
                dependDropdown.classList.remove('pointer-events-none');
                
                for (let i = 0; i < ids.length; i++) {
                    const option = document.createElement('option');
                    option.value = ids[i];
                    option.text = names[i];
                    formVersionSelect.appendChild(option);
                }
            }
        } else {
            const defaultOption = document.createElement('option');
            defaultOption.value = "";
            defaultOption.text = "Select Grading Form";
            formVersionSelect.appendChild(defaultOption);
        }
    });
    
    
    }

}