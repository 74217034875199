import Hammer from "hammerjs";
import * as cornerstone from "cornerstone-core";
import cornerstoneTools from "cornerstone-tools";
import cornerstoneMath from "cornerstone-math";
import ProbeTool from "../../src/Probe";
import dicomParser from "dicom-parser";
import cornerstoneWADOImageLoader from "cornerstone-wado-image-loader";
import * as cornerstoneWebImageLoader from "cornerstone-web-image-loader";
import {
  PanTool,
  WwwcTool,
  ZoomMouseWheelTool,
  LengthTool,
  ArrowAnnotateTool,
  AngleTool,
  RectangleRoiTool,
  FreehandRoiTool,
  EraserTool,
  EllipticalRoiTool,
} from "cornerstone-tools";

// cornerstone_grading_module.js
export default class CornerstoneGradingModule {
  constructor({
    formSaveUrl,
    formUserId,
    formStackId,
    notifications,
    annotation = {},
    element = null,
    imageTag = null,
    url = "",
    probCount = null,
    method = null,
    gradingForm = null,
    releaseEye = null,
  }) {
    this.saveUrl = formSaveUrl;
    this.user_id = formUserId;
    this.stack_id = formStackId;
    this.annotation = annotation;
    this.viewerElement = element;
    this.imageTag = imageTag;
    this.url = url;
    this.probCount = probCount;
    this.method = method;
    this.gradingForm = gradingForm;
    this.releaseEye = releaseEye;
    this.notifications = notifications;
    this.imageTagList = [
      { label: "Study Date", hex: "x00080020" },
      { label: "Study Time", hex: "x00080030" },
      { label: "Operators Name", hex: "x00081070" },
      { label: "Manufacturer's Model Name", hex: "x00081090" },
      { label: "Patient ID", hex: "x00100020" },
      { label: "Device Serial Number", hex: "x00181000" },
      { label: "Software Version(s)", hex: "x00181020" },
      { label: "Image Laterality", hex: "x00200062" },
    ];
    this.annotationIsLoadedOnce = {};
    this.images = [];
    this.currentImage = 0;
    this.selectedTool = "";
    this.LEFT_CLICK = 1;
    this.MIDDLE_CLICK = 4;
    this.initializeCornerstone();
  }

  async initializeCornerstone() {
    // Set external dependencies for cornerstone and tools
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.Hammer = Hammer;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
    cornerstoneWADOImageLoader.external.dicomParser = dicomParser;
    cornerstoneWADOImageLoader.configure({
      beforeSend: function (xhr) {},
      useWebWorkers: true,
    });
    // Initialize cornerstoneTools with your preferred configuration
    cornerstoneTools.init({
      mouseEnabled: true,
      touchEnabled: true,
      globalToolSyncEnabled: true,
      showSVGCursors: true,
    });

    // Configure the image loader error handler
    cornerstoneTools.loadHandlerManager.setErrorLoadingHandler(
      (element, imageId, error) => {
        console.error(`Error loading image: ${imageId}`, error);
      }
    );

    // Enable cornerstone on the element (viewerElement in this case)
    cornerstone.enable(this.viewerElement);

    // Load the initial image or perform additional setup
    if (this.url) {
      await this.imageLoader(this.url);
    }

    // Attach event listeners to the cornerstone element
    this.addEventListeners();
  }

  async imageLoader(imageUrl) {
    // This method will load the image. You can customize it according to your project.
    // Example for loading an image using the WADO image loader.
    if (!imageUrl) return;

    const imageId = `wadouri:${imageUrl}`;
    cornerstone
      .loadAndCacheImage(imageId)
      .then((image) => {
        // Display the image in the viewer
        cornerstone.displayImage(this.viewerElement, image);
      })
      .catch((error) => {
        console.error(`Error loading image from ${imageUrl}`, error);
      });
  }

  addEventListeners() {
    // Attach cornerstone-specific event listeners
    this.viewerElement.addEventListener("cornerstonenewimage", (e) => {
      // Reset viewer and adjust displayedArea on new image
      cornerstone.reset(this.viewerElement);
      e.detail.viewport.displayedArea.brhc.x = e.detail.image.width;
      e.detail.viewport.displayedArea.brhc.y = e.detail.image.height;
    });

    // Attach other cornerstoneTools event listeners if needed
    const toolEvents = cornerstoneTools.EVENTS;
    if (cornerstoneTools && cornerstoneTools.eventTarget) {
      cornerstoneTools.eventTarget.addEventListener(
        toolEvents.TOOL_ACTIVATED,
        (event) => {
          console.log("Tool activated:", event.detail.toolName);
        }
      );

      cornerstoneTools.eventTarget.addEventListener(
        toolEvents.TOOL_DEACTIVATED,
        (event) => {
          console.log("Tool deactivated:", event.detail.toolName);
        }
      );

      cornerstoneTools.eventTarget.addEventListener(
        toolEvents.STACK_CHANGED,
        (event) => {
          console.log("Stack changed:", event.detail.stack);
        }
      );
    }
  }

  imageLoader(imageUrl) {
    imageUrl = document.location.origin + "/" + imageUrl;
    this.imageTag.innerHTML = "";
    if (imageUrl) {
      let imageIds = [];
      let imageIdRoot = "wadouri:" + imageUrl;
      let stack = {};

      if (imageUrl.includes(".dcm")) {
        cornerstoneWADOImageLoader.wadouri.dataSetCacheManager
          .load(imageUrl, cornerstoneWADOImageLoader.internal.xhrRequest)
          .then((dataSet) => {
            let numFrames = dataSet.intString("x00280008");

            this.imageTagList.forEach((t) => {
              if (dataSet.intString(t.hex)) {
                this.imageTag.innerHTML += `<div>${
                  t.label
                } : ${dataSet.intString(t.hex)}</div>`;
              }
            });

            if (!numFrames) {
              imageIds.push(imageIdRoot);
            } else {
              for (let i = 0; i < numFrames; i++) {
                imageIds.push(imageIdRoot + "?frame=" + i);
              }
            }

            stack = {
              currentImageIdIndex: 0,
              imageIds: imageIds,
            };

            if (imageIds.length > 2) {
              cornerstone.loadAndCacheImage(imageIds[0]).then(
                (image) => {
                  cornerstone.displayImage(this.viewerElement, image);
                  cornerstoneTools.addStackStateManager(this.viewerElement, [
                    "stack",
                  ]);
                  cornerstoneTools.addToolState(
                    this.viewerElement,
                    "stack",
                    stack
                  );

                  stack.imageIds.forEach((imageId) => {
                    this.loadExistingAnnotationsForImage(imageId);
                  });

                  this.addCornerstoneToolsAndAnnotations();
                },
                function (err) {
                  console.log("loadAndCacheImage error", err);
                }
              );
            } else {
              cornerstone.loadAndCacheImage(imageUrl).then(
                (image) => {
                  cornerstone.displayImage(this.viewerElement, image);
                  this.addCornerstoneToolsAndAnnotations();
                  this.loadExistingAnnotationsForImage(imageUrl);
                },
                function (err) {
                  console.log("loadImage error", err);
                }
              );
            }
          });
      } else {
        cornerstone.loadAndCacheImage(imageUrl).then((image) => {
          cornerstone.displayImage(this.viewerElement, image);
          this.addCornerstoneToolsAndAnnotations();
          this.loadExistingAnnotationsForImage(imageUrl);
        });
      }
    }
  }

  addCornerstoneToolsAndAnnotations(imageUrl) {
    cornerstoneTools.addTool(PanTool);
    cornerstoneTools.setToolActive("Pan", {
      mouseButtonMask: this.MIDDLE_CLICK,
    });

    // Activate WWWc tool on Left Click Drag
    cornerstoneTools.addTool(WwwcTool);
    cornerstoneTools.setToolActive("Wwwc", {
      mouseButtonMask: this.LEFT_CLICK,
    });

    // Activate Zoom Mouse Wheel Tool
    cornerstoneTools.addTool(ZoomMouseWheelTool, {
      configuration: {
        invert: false,
        preventZoomOutsideImage: false,
        minScale: 0.01,
        maxScale: 20.0,
      },
    });
    cornerstoneTools.setToolActive("ZoomMouseWheel", {});

    // Add other tools
    const tools = [
      LengthTool,
      ArrowAnnotateTool,
      AngleTool,
      RectangleRoiTool,
      FreehandRoiTool,
      EraserTool,
      ProbeTool,
      EllipticalRoiTool,
    ];

    tools.forEach((tool) => {
      cornerstoneTools.addTool(tool);
    });

    // Register cornerstone tools events
    this.viewerElement.addEventListener(
      "cornerstonetoolsmeasurementcompleted",
      (e) => {
        // console.log(`------${e.detail.toolName}-----`);
        // console.table(e.detail.measurementData);
      }
    );

    let jsonData = this.probCount; // Initialize count variable for circles
    let probeAnnotation = JSON.parse(jsonData);
    let circleCount = probeAnnotation.data ? probeAnnotation.data.length : 0;

    this.viewerElement.addEventListener(
      "cornerstonetoolsmeasurementadded",
      (e) => {
        if (e.detail.toolName !== "Probe") {
          return;
        }
        circleCount++;
        this.appendCountInTheForm(circleCount); // This should call the method
      }
    );

    this.viewerElement.addEventListener(
      "cornerstonetoolsmeasurementremoved",
      (e) => {
        if (e.detail.toolName !== "Probe") {
          return;
        }
        circleCount--;
        this.appendCountInTheForm(circleCount);
      }
    );

    this.refreshTools();
  }

  appendCountInTheForm(counts) {
    this.gradingForm.components.forEach((component) => {
      if (component.components) {
        component.components.forEach((question) => {
          if (question.key === "marks_count_" + this.releaseEye) {
            const countComponent = document.querySelector(
              `.formio-component-${question.key}`
            );
            if (countComponent) {
              const inputElement = countComponent.querySelector("input");
              if (inputElement) {
                // Set the value programmatically
                inputElement.value = counts;
                // Trigger an input event to notify the framework of the value change
                const event = new Event("input", { bubbles: true });
                inputElement.dispatchEvent(event);
              }
            }
          }
        });
      }
    });
  }

  loadExistingAnnotationsForImage(url) {
    if (this.annotation[url] && !this.annotationIsLoadedOnce[url]) {
      cornerstoneTools.globalImageIdSpecificToolStateManager.restoreImageIdToolState(
        url,
        this.annotation[url]
      );
      this.annotationIsLoadedOnce[url] = true;
      this.refreshTools();
    }
  }

  refreshTools() {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
      "Probe",
    ];
    tools.forEach((tool) => {
      cornerstoneTools.setToolActive(tool, { mouseButtonMask: 1 });
    });
  }

  readImages() {
    const images = document.querySelectorAll(".image");
    images.forEach((i) => {
      this.images.push({
        id: i.getAttribute("data-id"),
        url: i.getAttribute("data-url"),
        elem: i,
      });
    });
  }

  loadImage(url, id) {
    url = document.location.origin + url;
    cornerstone.loadAndCacheImage(url).then((image) => {
      this.loadExistingAnnotationsForImage(url);
      cornerstone.displayImage(this.viewerElement, image);
      cornerstone.enable(this.viewerElement);
    });
  }

  changeImage(e, elem) {
    let target = e ? e.target : elem;
    this.imageLoader(target.dataset.url);

    if (e) {
      this.currentImage = target.dataset.idx;
      console.log(`current index selected ${this.currentImage}`);
    }

    this.imageTag.innerHTML = "";
  }

  nextImage(e) {
    console.log(`current index: ${this.currentImage}`);
    const len = this.images.length;
    this.currentImage = Math.abs((this.currentImage + 1) % len);
    console.log(` after: ${this.currentImage}`);
    this.changeImage(null, this.images[this.currentImage].elem);
    e.preventDefault();
  }

  previousImage(e) {
    console.log(`current index: ${this.currentImage}`);
    const len = this.images.length;
    this.currentImage = Math.abs(this.currentImage + len - 1) % len;
    console.log(` after: ${this.currentImage}`);
    this.changeImage(null, this.images[this.currentImage].elem);
    e.preventDefault();
  }

  toggleTags(e) {
    this.imageTag.classList.toggle("hidden");
    e.preventDefault();
  }

  getCircleCount() {
    return cornerstoneTools.getToolState(this.viewerElement, "Probe")
      ? cornerstoneTools.getToolState(this.viewerElement, "Probe").data.length
      : 0;
  }

  labelTool(e) {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
      "Probe",
    ];

    tools.forEach((tool) => {
      cornerstoneTools.setToolEnabled(tool, {
        mouseButtonMask: this.LEFT_CLICK,
      });
    });

    this.viewerElement.addEventListener("cornerstonetoolsmousedown", (e) => {
      const coord = e.detail.currentPoints.canvas;
      const mouseBtn = e.detail.buttons;
      // if its a right click
      if (mouseBtn === 2) {
        tools.forEach((tool) => {
          const state = cornerstoneTools.getToolState(this.viewerElement, tool);
          if (state) {
            state.data.forEach((d) => {
              const isNear = cornerstoneTools
                .getToolForElement(this.viewerElement, tool)
                .__proto__.pointNearTool(this.viewerElement, d, coord);
              if (isNear)
                console.log(`${tool} with ${d.uuid} near : ${isNear}`);
              cornerstoneTools.setToolActive(tool, {
                mouseButtonMask: this.LEFT_CLICK,
              });
              // when near is detected see if we can have the annotation highlited green
            });
          }
        });
      }
    });
    e.preventDefault();
  }

  activateTool(toolName) {
    // Activate the tool using cornerstoneTools
    cornerstoneTools.setToolActive(toolName, {
      mouseButtonMask: this.LEFT_CLICK,
    });
  }

  setSelectedTool(toolName) {
    this.selectedTool = toolName;
  }

  submitGrading(e) {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
      "Probe",
    ];
    const annotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    this.saveGrading(annotations);
    e.preventDefault();
  }

  saveGrading(annotations) {
    fetch(this.saveUrl, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": document.head
          .querySelector('meta[name="csrf-token"]')
          .getAttribute("content"),
      },
      method: this.method,
      body: JSON.stringify({
        grading: {
          comment: "Update",
          annotations: annotations,
          user_id: this.user_id,
          stack_id: this.stack_id,
        },
      }),
    }).then((response) => {
      console.log(response);
    });
  }

  defaultSaveAnnotation(e) {
    const tools = [
      "Length",
      "ArrowAnnotate",
      "Angle",
      "EllipticalRoi",
      "RectangleRoi",
      "FreehandRoi",
    ];
    // const annotations = {};

    const annotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();

    this.saveGrading({ ...annotations, ...this.annotation });
  }

  calculateAngle(apiUrl, apiKey) {
    const annotations =
      cornerstoneTools.globalImageIdSpecificToolStateManager.saveToolState();
    let annotationsData = annotations
      ? annotations[Object.keys(annotations)[0]]
      : null;
    if (annotationsData) {
      let lengthData = annotationsData.Length;
      if (lengthData && lengthData.data.length === 2) {
        // Extracting Length data from annotationsData
        const annotationsData = lengthData.data.map((annotation) => ({
          visible: annotation.visible,
          active: annotation.active,
          invalidated: annotation.invalidated,
          handles: {
            start: annotation.handles.start,
            end: annotation.handles.end,
            textBox: annotation.handles.textBox,
          },
          uuid: annotation.uuid,
          length: annotation.length,
          unit: annotation.unit,
        }));
        fetch(apiUrl, {
          headers: {
            "X-API-KEY": apiKey,
            "Content-Type": "application/json; charset=utf-8",
          },
          method: "POST",
          body: JSON.stringify({
            Length: {
              data: annotationsData,
            },
            image_url: this.url,
            user_id: this.user_id,
            stack_id: this.stack_id,
          }),
        })
          .then((response) => {
            response.status == 200
              ? this.notifications.success("Calculated Successfully!")
              : this.notifications.error("Not Done!");
            return response.json();
          })
          .then((data) => {
            document.querySelector('input[name="data[angle]"]').value =
              data.data.lens_rotation_degrees;
            document.querySelector('input[name="data[direction]"]').value =
              data.data.direction;
            this.gradingForm.submission.data.angle =
              "angle" in this.gradingForm.submission.data
                ? data.data.lens_rotation_degrees
                : data.data.lens_rotation_degrees;
            this.gradingForm.submission.data.direction =
              "direction" in this.gradingForm.submission.data
                ? data.data.direction
                : data.data.direction;
          });
      } else {
        alert("Two lines required to calculate angle");
      }
    } else {
      alert("Draw two lines through Length Tool to Calculate Angle");
    }
  }
}
