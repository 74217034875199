import { Controller } from 'stimulus';
import $ from 'jquery';
export default class extends Controller {

    static targets = ['url', 'allCheckboxes', 'textarea', 'tooltip', 'loadingImage', 'transmissionNumber','cornerstoneViewer', 'octViewer', 'toggle']

    async initialize() {

        this.transmissionsSaveUrl = document.location.origin + this.data.get("transmissionsSubmitPath") + ".json";
        let baseUrl = this.data.get("transmissionsApiUrl");
        this.clientId = this.data.get("clientId");
        this.clientSecret = this.data.get("clientSecret");
        this.transDate = this.data.get("transDate");
        this.studyCode = this.data.get("studyCode");
        this.url = baseUrl + "?trans_date=" + this.transDate + "&study_code=" + this.studyCode;
        if (this.hasLoadingImageTarget) {
            this.hideLoader();
        }
    }
    connect() {
    if (this.hasCornerstoneViewerTarget) {
        this.cornerstoneViewerTarget.addEventListener('change', this.toggleCheckboxes.bind(this));
    }
    if (this.hasOctViewerTarget) {
        this.octViewerTarget.addEventListener('change', this.toggleCheckboxes.bind(this));
    }
    $('.select2-search-box').select2();
    // Event listener for when the dropdown is opened
    $('.select2-search-box').on('select2:open', function() {
        // Use a timeout to ensure the focus is set correctly
        setTimeout(function() {
        var searchField = $('.select2-search__field');
        if (searchField.length > 0) {
            searchField[0].focus();
        }
        }, 0); 
    });
     
        $(".bcc_email").select2({
            tags: true,
            tokenSeparators: [',', ' ']
        });
        $(".value").select2({
            tags: true,
            tokenSeparators: [',', ' ']
        });
    }
    toggleCheckboxes(event) {
        if (event.target === this.cornerstoneViewerTarget && this.cornerstoneViewerTarget.checked) {
            this.octViewerTarget.checked = false;
        } else if (event.target === this.octViewerTarget && this.octViewerTarget.checked) {
            this.cornerstoneViewerTarget.checked = false;
        }
    }
    loadwindow() {
        this.url = this.urlTarget.getAttribute('data-link');
        window.location = this.url;
    }

    selectAllImages() {
        const selected = document.getElementsByClassName("select_all_stack_images")[0].checked;
        var all_images = this.allCheckboxesTarget.querySelectorAll('input[type="checkbox"]');
        for (var i = 0; i < all_images.length; i++) {
            // And stick the checked ones onto an array...
            if (selected) {
                all_images[i].checked = true;
            } else {
                all_images[i].checked = false;
            }
        }
    }
   
    
    confirmDelete(event) {
        event.preventDefault();
        const linkElement = event.currentTarget;

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, do it!',
            cancelButtonText: 'Cancel'
        }).then((result) => {
            if (result.isConfirmed) {
                window.location = linkElement.getAttribute('href');
            }
        });
    }
    confirmRemovalStudyUser(event) {
        event.preventDefault();
        const target = event.target.closest("tr");

        Swal.fire({
            title: "Are you sure?",
            text: "You want to remove the user from this study?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#3085d6",
            confirmButtonText: "Yes, remove",
        }).then((result) => {
            if (result.isConfirmed) {
                const url = this.element.getAttribute("href");
                Rails.ajax({
                    type: "DELETE",
                    url: url,
                    dataType: "json",
                    success: () => {
                        Swal.fire("Removed!", "The user has been removed from the study.", "success");
                        if (target) {
                            target.remove();
                        }
                    },
                    error: () => {
                        Swal.fire("Can't Removed", "You cannot delete the owner of the study", "error");
                    }
                });
            }
        });
    }
    toggleTextarea() {
        const comments = document.getElementsByClassName("show_hide_comments")[0]
        if (comments.style.display === 'none') {
            comments.style.display = 'block';
        } else {
            comments.style.display = 'none'
        }
    }
    showRejectedForm(event) {
        event.preventDefault();
        let each_form = document.getElementById("show_single_form_" + event.currentTarget.id);
        if (each_form.classList.contains('hidden')) {
            each_form.classList.remove("hidden");
        }
        else {
            each_form.classList.add("hidden");
        }
    }
    grandfatherReject(event) {
        document.getElementById("grandfathering_certification_form").style.display = "none";
        let reject_form = document.getElementById("grandfathering_rejected_form")
        if (reject_form.style.display === "none") {
            reject_form.style.display = "block";
        }
        event.preventDefault();
        // To import transmissions from the OCAP
    }
    importTransmissions() {
        this.showLoader();
        this.getTransmissions()
            .then(() => {
                this.hideLoader();
                window.location.reload();
            })
            .catch((error) => {
                // Handle any errors, and hide the loader in case of error as well
                console.error("Error occurred during import:", error);
                this.hideLoader();
            });
    }

    getTransmissions() {
        return fetch(this.url, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "Client-Id": this.clientId,
                "Client-Secret": this.clientSecret,
            },
            method: "GET",
        })
            .then((response) => response.json())
            .then((result) => {
                return this.pushTransmissions(result.data);
            });
    }

    pushTransmissions(responseData) {
        return fetch(this.transmissionsSaveUrl, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                "X-CSRF-Token": document.head
                    .querySelector('meta[name="csrf-token"]')
                    .getAttribute("content"),
            },
            method: "POST",
            body: JSON.stringify({
                import_data: {
                    transmissions: responseData.data,
                },
            }),
        });
    }

    // copy transmission number

    copy(event) {

        const transmissionNumber = event.currentTarget.querySelector('[data-common-script-target="transmissionNumber"]').textContent.trim();
        const tooltip = event.currentTarget.querySelector('[data-common-script-target="tooltip"]');
        navigator.clipboard.writeText(transmissionNumber)
            .then(() => {
                tooltip.textContent = 'Copied!';
                setTimeout(() => {
                    tooltip.textContent = 'Copy to clipboard';
                }, 1000);
            })
            .catch((error) => {
                console.error('Copying to clipboard failed:', error);
            });
    }

    showLoader() {
        this.loadingImageTarget.classList.add("visible");
    }

    hideLoader() {
        this.loadingImageTarget.classList.remove("visible");
    }

    switchstatus(event){
        event.preventDefault();
        const url = this.element.dataset.switchUrl;
        const status = this.element.dataset.status;
        const message = status === 'true' ? 'unlock' : 'lock'

        Swal.fire({
            title: `Are you sure, you want to ${message} this user?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `Yes!`
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                })
                .then(response => {
                    // Check if response is okay
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    Swal.fire({
                        title: 'Success!',
                        text: data.message,
                        icon: 'success',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            location.reload();
                        }
                    });
                }) 
            }
        });    
    }



}
