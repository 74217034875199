import { Controller } from "stimulus";
import $ from 'jquery';
require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
  static targets = [
    "tabs", "contenttabs", "selectedSummaryvalue", "links",
    "selectedSummaryvalueSe", "selectedSummaryvalueExpectedOu",
    "selectedSummaryvalueExpectedOe", "selectedGradingvalueOu",
    "selectedGradingvalueSe", "selectedSummaryvalueExpectedOuNo",
    "selectedSummaryvalueExpectedSeNo", "selectedSummaryvalueExpectedNoDual",
    "selectedSummaryvalueExpectedNoSingle", "container","selectedEligibilityOu", "selectedEligibilitySe","inputrows",
    "rowTemplate", "rowTemplateQcSe","rowTemplateGradingOu", "rowTemplateGradingSe","selectedCertificate",
    "saveBtn"
  ];
  
  connect() {
    console.log("Connected to Stimulus controller study summary");
    $('.visits-search').select2();
    this.initializeSelect2();
    this.initializeListeners();
    this.activateDefaultTab();
    this.currentTargetContent = null;
  }
  activateDefaultTab() {
    const defaultTab = this.tabsTargets.find(tab => tab.dataset.defaultTab === "true");
    if (defaultTab) {
      this.summaryTabs({ currentTarget: defaultTab, preventDefault: () => {} });
    }
  }
  printPage(event) {
    const targetContentId = this.currentTargetContentId; // Ensure this is set correctly
    const studyId = event.currentTarget.dataset.studyId;
    const studyCode = event.currentTarget.dataset.studyCode;
    const tabText = this.currentTabText;
    // Create a table element for Study Name and Study Code
    const detailsTable = document.createElement('table');
    detailsTable.style.width = '100%'; // Adjust as needed
    detailsTable.style.borderCollapse = 'collapse'; // Optional
    detailsTable.style.margin = '0 auto'; // Center the table

    // Create a single row
    const row = detailsTable.insertRow();

    // Insert Study Name cell
    const studyNameCell = row.insertCell();
    studyNameCell.innerHTML = `<strong>Study Name:</strong> <span style="padding-left: 20px;">${studyId}</span>`;
    studyNameCell.style.borderBottom = '1px solid #ddd';
    studyNameCell.style.padding = '8px';
    studyNameCell.style.textAlign = 'center';

    // Insert Study Code cell
    const studyCodeCell = row.insertCell();
    studyCodeCell.innerHTML = `<strong>Study Code:</strong> <span style="padding-left: 20px;">${studyCode}</span>`;
    studyCodeCell.style.borderBottom = '1px solid #ddd';
    studyCodeCell.style.padding = '8px';
    studyCodeCell.style.textAlign = 'center';

    // Create a custom header element for study details
    const studyDetailsHeader = document.createElement('div');
    studyDetailsHeader.classList.add('custom-header');
    studyDetailsHeader.textContent = tabText;
    studyDetailsHeader.style.padding = '10px';
    studyDetailsHeader.style.textAlign = 'center';
    studyDetailsHeader.style.fontWeight = 'bold';
    studyDetailsHeader.style.marginBottom = '0px';
    studyDetailsHeader.style.fontSize = '22px';

    // Create a wrapper element
    const wrapper = document.createElement('div');
    wrapper.style.textAlign = 'center'; // Center align all elements
    wrapper.style.marginBottom = '5px'; // Space between elements

    // Create a logo element
    const logo = document.createElement('img');
    const basePath = document.querySelector('meta[name="asset-base-path"]').getAttribute('content');
    logo.src = basePath;
    logo.style.display = 'block';
    logo.style.margin = '0 auto 10px auto';
    logo.style.width = '250px'; // Set the desired width
    logo.style.height = 'auto'; // Maintain aspect ratio

    // Set up the onload event to append elements only after the logo is fully loaded
    logo.onload = () => {
        // Append elements to the wrapper
        wrapper.appendChild(logo);
        wrapper.appendChild(studyDetailsHeader);
        wrapper.appendChild(detailsTable);

        // Prepend the wrapper to the document body
        document.body.prepend(wrapper);

        // Save original inputs' references and replace with divs/spans for printing
        const inputs = document.querySelectorAll('input[type="text"], input[type="number"], textarea, select'); // Adjust selector as needed
        const inputReplacements = [];

        inputs.forEach(input => {
            const replacement = document.createElement('div');
            replacement.style.whiteSpace = 'pre-wrap'; // To keep newlines and wrap text
            replacement.style.border = '1px solid #ccc'; // Optional: style to indicate it's a text input
            replacement.style.padding = '4px'; // Optional: add padding for better visuals
            replacement.style.margin = '2px 0'; // Optional: add margin for better visuals

            if (input.tagName.toLowerCase() === 'select') {
                // Get the selected option's data-short-name attribute
                const selectedOption = input.options[input.selectedIndex];
                if (selectedOption) {
                    const shortName = selectedOption.getAttribute('data-parent-name') || selectedOption.textContent;
                    const recordDiv = document.createElement('div');
                    recordDiv.textContent = shortName.trim() || ' '; // Display 'no data found' if empty
                    replacement.appendChild(recordDiv);
                } else {
                    const noDataDiv = document.createElement('div');
                    noDataDiv.textContent = ' ';
                    replacement.appendChild(noDataDiv);
                }
            } else {
                // Split the input value by '|' and '(' using a regular expression
                const records = input.value.split(/[|()]/).filter(record => record.trim());
                if (records.length === 0) {
                    const noDataDiv = document.createElement('div');
                    noDataDiv.textContent = ' ';
                    replacement.appendChild(noDataDiv);
                } else {
                    records.forEach(record => {
                        const recordDiv = document.createElement('div');
                        recordDiv.textContent = record.trim(); // Trim any excess whitespace
                        replacement.appendChild(recordDiv);
                    });
                }
            }

            inputReplacements.push({ original: input, replacement });
            input.parentNode.replaceChild(replacement, input);
        });

        // Hide elements before printing
        const elementsToHide = [
            '.savebtnHide',
            '.printhidestudies',
            '.printhidenavli',
            '.printhide',
            '.printhidenav',
            '.printfoothide',
            '.printsidehide',
            '.hideicon',
            '.hideicon2',
            // Add more selectors as needed
        ];

        const hiddenElements = elementsToHide.flatMap(selector => Array.from(document.querySelectorAll(selector)));

        hiddenElements.forEach(element => {
            element.style.display = 'none';
        });

        // Trigger the browser's print functionality
        window.print();

        // Restore hidden elements after printing
        hiddenElements.forEach(element => {
            element.style.display = '';
        });

        // Restore the original inputs after printing
        inputReplacements.forEach(({ original, replacement }) => {
            replacement.parentNode.replaceChild(original, replacement);
        });

        // Remove the wrapper after printing
        wrapper.remove();
    };

    // Handle image load errors
    logo.onerror = () => {
        alert('Failed to load the logo. Proceeding with print without the logo.');

        wrapper.appendChild(studyDetailsHeader);
        wrapper.appendChild(detailsTable);

        document.body.prepend(wrapper);

        // Hide elements that should not be printed
        const elementsToHide = [
            '.savebtnHide',
            '.printhidestudies',
            '.printhidenavli',
            '.printhide',
            '.printhidenav',
            '.printfoothide',
            '.printsidehide',
            '.hideicon',
            '.hideicon2',
            // Add more selectors as needed
        ];

        const hiddenElements = elementsToHide.flatMap(selector => Array.from(document.querySelectorAll(selector)));

        hiddenElements.forEach(element => {
            element.style.display = 'none';
        });

        // Print the page
        window.print();

        // Restore visibility of hidden elements
        hiddenElements.forEach(element => {
            element.style.display = '';
        });

        // Remove the wrapper after printing
        wrapper.remove();
    };
}


  initializeSelect2() {
    $('.visits-search').select2({});
    this.studyId = this.data.get('studyId');
  }

  initializeListeners() {
    this.inputrowsTargets.forEach((element) => {
      element.addEventListener('input', (event) => this.logInputValue(event));
    });
  }

  logInputValue(event) {
    const inputValue = event.target.value;

    if (inputValue > 1001) {
      this.showMessage('The input value is to greater.');
      event.target.value = ''; 
    }
  }

  showMessage(message) {
    const modal = document.createElement('div');
    modal.className = 'fixed transform right-0 p-4 bg-red-600 text-white rounded z-50';
    modal.textContent = message;
  
    document.body.appendChild(modal);
  
    setTimeout(function () {
      modal.remove();
    }, 3000); // Remove the modal after 3 seconds
  }

  collectData() {
    const QcOu_stat = this.selectedSummaryvalueTarget.value; // Access the selected value
    const QcSe_stat = this.selectedSummaryvalueSeTarget.value;
    const selectedSummarySelect = $(this.selectedGradingvalueOuTarget);
    const gradingOu_stat = selectedSummarySelect.val();
    const selectedgradingValue = $(this.selectedGradingvalueSeTarget);
    const gradingSe_stat = selectedgradingValue.val();
    const selectedEligibityOu =  this.selectedEligibilityOuTarget.value;
    const selectedEligibitySe =  this.selectedEligibilitySeTarget.value;
    const selectedCertification =  this.selectedCertificateTarget.value;  
    const qcOu_exp =  this.selectedSummaryvalueExpectedOuTarget.value;  
    const qcSe_exp =  this.selectedSummaryvalueExpectedOeTarget.value;  
    const GradingOu_exp =  this.selectedSummaryvalueExpectedOuNoTarget.value;  
    const GradingSe_exp =  this.selectedSummaryvalueExpectedSeNoTarget.value; 
    const DualGrading_exp =  this.selectedSummaryvalueExpectedNoDualTarget.value; 
    const SingleGrading_exp =  this.selectedSummaryvalueExpectedNoSingleTarget.value; 
    const qc_inputs = this.element.querySelectorAll('input[id^="number_of_qc_eyes_req_"]');
    const grading_inputs = this.element.querySelectorAll('input[id^="number_of_grading_eyes_req_"]');
    const grading_dual_single_inputs = this.element.querySelectorAll('input[id^="number_of_grading_"]');
    const eligibility_inputs = this.element.querySelectorAll('input[id^="eligibility_req_"]');
    const certification_inputs = this.element.querySelectorAll('input[id^="certification_"]');
    const phase = document.getElementById('phase');
    const initial_sow_id = document.getElementById('initial_sow_id');
    const initial_sow_date = document.getElementById('initial_sow_date');
    const current_sow_id = document.getElementById('current_sow_id');
    const current_sow_date = document.getElementById('current_sow_date');
    const subject_screened = document.getElementById('subject_screened');
    const subjects_enrolled = document.getElementById('subjects_enrolled');
    const study_parts = document.getElementById('study_parts');
    const expected_sites = document.getElementById('expected_sites');
    const project_start_date = document.getElementById('project_start_date');
    const expected_fpfv_date = document.getElementById('expected_fpfv_date');
    const expected_lpfv_date = document.getElementById('expected_lpfv_date');
    const expected_lplv_date = document.getElementById('expected_lplv_date');
    const expected_study_closure_date = document.getElementById('expected_study_closure_date');
    const number_grade_eye_req = document.getElementById('number_grade_eye_req');
    const number_qc_eye_req = document.getElementById('number_qc_eye_req');
    const number_of_imaging_visits = document.getElementById('number_of_imaging_visits');
    const unschedule_event = document.getElementById('unschedule_event');
    const screen_failur_action = document.getElementById('screen_failur_action');
   
    
    const qc_data = {};
    const grading_data = {};
    const grading_dual_single_data = {};
    const eligibility_data = {};
    const certification_data = {};

    qc_inputs.forEach(input => {
      qc_data[input.id] = input.value;
    });
    grading_inputs.forEach(input => {
      grading_data[input.id] = input.value;
    });
    grading_dual_single_inputs.forEach(input => {
      grading_dual_single_data[input.id] = input.value;
    });
    eligibility_inputs.forEach(input => {
      eligibility_data[input.id] = input.value;
    });
    certification_inputs.forEach(input => {
      certification_data[input.id] = input.value;
    });

    const url = `${document.location.origin}/studies/${this.studyId}/study_summary_submission`;
    
    fetch(url, {
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        method: 'POST',
        body: JSON.stringify({
          qc_data: qc_data,
          grading_data:grading_data,
          grading_dual_single_data:grading_dual_single_data,
          eligibility_data:eligibility_data,
          certification_data:certification_data,
          phase: phase.value,
          initial_sow_id: initial_sow_id.value,
          initial_sow_date: initial_sow_date.value,
          current_sow_id: current_sow_id.value,
          current_sow_date: current_sow_date.value,
          subject_screened: subject_screened.value,
          subjects_enrolled: subjects_enrolled.value,
          study_parts: study_parts.value,
          expected_sites: expected_sites.value,
          project_start_date: project_start_date.value,
          expected_fpfv_date: expected_fpfv_date.value,
          expected_lpfv_date: expected_lpfv_date.value,
          expected_lplv_date: expected_lplv_date.value,
          expected_study_closure_date: expected_study_closure_date.value,
          number_grade_eye_req: number_grade_eye_req.value,
          number_qc_eye_req: number_qc_eye_req.value,
          number_of_imaging_visits: number_of_imaging_visits.value,
          unschedule_event: unschedule_event.value,
          screen_failur_action: screen_failur_action.value,
          QcOu_state: QcOu_stat,
          QcSe_state: QcSe_stat,
          gradingOu_state: gradingOu_stat,
          gradingSe_state: gradingSe_stat,
          selectedEligibityOu: selectedEligibityOu,
          selectedEligibitySe: selectedEligibitySe,
          selectedCertification: selectedCertification,
          qcOu_exp: qcOu_exp,
          qcSe_exp: qcSe_exp,
          GradingOu_exp: GradingOu_exp,
          GradingSe_exp: GradingSe_exp,
          DualGrading_exp: DualGrading_exp,
          SingleGrading_exp: SingleGrading_exp,
        })
    }).then(response => {
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Updated',
          showConfirmButton: false,
          timer: 1000
      });  
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'An error occurreds',
          showConfirmButton: false,
          timer: 2000
      });  
      }
    })
}

//function for updating study_side id when focusout
summaryTabs(event) {
  event.preventDefault();

  const clickedTab = event.currentTarget;
  const targetContentId = clickedTab.getAttribute("href");
  this.currentTargetContentId = targetContentId; // Store the targetContentId
  const tabText = clickedTab.textContent.trim();
  this.currentTabText = tabText;
  this.tabsTargets.forEach(tab => {
    tab.classList.remove("font-bold", "bg-blue-100");
  });
  
  // Add the background color and font-bold class to the clicked tab
  clickedTab.classList.add("font-bold", "bg-blue-100");

  // Hide all tab contents
  this.contenttabsTargets.forEach(contenttabs => {
    contenttabs.classList.add("hidden");
  });

  // Show the target tab content
  const targetContent = document.querySelector(targetContentId);
  targetContent.classList.remove("hidden");

  if (targetContentId === '#qc_details') {
    const qcDetails = document.querySelector('#qc_details_se');
    const qcDetailsSe = document.querySelector('#qc_details_se');
    const qcDetailsExpectedOu = document.querySelector('#qc_details_expected_ou');
    const qcDetailsExpectedOe = document.querySelector('#qc_details_expected_oe');

    if (qcDetails) {
      qcDetails.classList.remove("hidden");
      const selectedSummarySelectOe = $(this.selectedSummaryvalueTarget);
      const selectedSummaryValueOe = selectedSummarySelectOe.val();
      if (selectedSummaryValueOe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetab');
        hidetabSeDiv.classList.remove("hidden");
      }
    }

    if (qcDetailsSe) {
      qcDetailsSe.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedSummaryvalueSeTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetabSe');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (qcDetailsExpectedOu) {
      qcDetailsExpectedOu.classList.remove("hidden");
      const selectedSummarySelectExpectedOu = $(this.selectedSummaryvalueExpectedOuTarget);
      const selectedSummaryValueExpectedOu = selectedSummarySelectExpectedOu.val();
      if (selectedSummaryValueExpectedOu === "yes") {
        const hidetabExpectedOuDiv = document.getElementById('hidetabExpectedOu');
        hidetabExpectedOuDiv.classList.remove("hidden");
      }
    }
    if (qcDetailsExpectedOe) {
      qcDetailsExpectedOe.classList.remove("hidden");
      const selectedSummarySelectExpectedOe = $(this.selectedSummaryvalueExpectedOeTarget);
      const selectedSummaryValueExpectedOe = selectedSummarySelectExpectedOe.val();
      if (selectedSummaryValueExpectedOe === "yes") {
        const hidetabExpectedOeDiv = document.getElementById('hidetabExpectedOe');
        hidetabExpectedOeDiv.classList.remove("hidden");
      }
    }
  } 
  else if (targetContentId === '#grading') {
    const gradingDetailsoe = document.querySelector('#grading_details_ou');
    const gradingDetailsSe = document.querySelector('#grading_details_se');
    const gradingOuNo = document.querySelector('#gradingOuNo');
    const gradingSeNo = document.querySelector('#gradingSeNo');
    const DualGradeNo = document.querySelector('#gradingNoDual');    
    const SingleGradeNo = document.querySelector('#gradingNoSingle'); 
    if (gradingDetailsoe) {
      gradingDetailsoe.classList.remove("hidden");
      const selectedSummarySelectOe = $(this.selectedGradingvalueOuTarget);
      const selectedSummaryValueOe = selectedSummarySelectOe.val();
      if (selectedSummaryValueOe === "yes") {
        const hidetabSeDiv = document.getElementById('hideGradingOu');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (gradingDetailsSe) {
      gradingDetailsSe.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedGradingvalueSeTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hideGradingSe');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (gradingOuNo) {
      gradingOuNo.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedSummaryvalueExpectedOuNoTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetabExpectedOuNo');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (gradingSeNo) {
      gradingSeNo.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedSummaryvalueExpectedSeNoTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetabExpectedSeNo');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (DualGradeNo) {
      DualGradeNo.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedSummaryvalueExpectedNoDualTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetabExpectedNoDual');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (SingleGradeNo) {
      SingleGradeNo.classList.remove("hidden");
      const selectedSummarySelectSe = $(this.selectedSummaryvalueExpectedNoSingleTarget);
      const selectedSummaryValueSe = selectedSummarySelectSe.val();
      if (selectedSummaryValueSe === "yes") {
        const hidetabSeDiv = document.getElementById('hidetabExpectedNoSingle');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    const additionalDivs = document.querySelectorAll('.additional-divs');
    additionalDivs.forEach(div => {
      div.classList.add("hidden");
    });
  }
  else if (targetContentId === '#eligibility') {
    const additionalDivs = document.querySelectorAll('.additional-divs');
    additionalDivs.forEach(div => {
      div.classList.add("hidden");
    });
    const eligibilitySelectOu = document.querySelector('#eligibilitySelectOu');
    const eligibilitySelectSe = document.querySelector('#eligibilitySelectSe');
    const CertificationSelect = document.querySelector('#CertificationSelect');
    if (eligibilitySelectOu) {
      eligibilitySelectOu.classList.remove("hidden");
      const selectedSummarySelectOe = $(this.selectedEligibilityOuTarget);
      const selectedSummaryValueOe = selectedSummarySelectOe.val();
      if (selectedSummaryValueOe === "yes") {
        const hidetabSeDiv = document.getElementById('eligibilityOuDev');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (eligibilitySelectSe) {
      eligibilitySelectSe.classList.remove("hidden");
      const selectedSummarySelectOe = $(this.selectedEligibilitySeTarget);
      const selectedSummaryValueOe = selectedSummarySelectOe.val();
      if (selectedSummaryValueOe === "yes") {
        const hidetabSeDiv = document.getElementById('eligibilitySeDev');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
    if (CertificationSelect) {
      CertificationSelect.classList.remove("hidden");
      const selectedSummarySelectOe = $(this.selectedCertificateTarget);
      const selectedSummaryValueOe = selectedSummarySelectOe.val();
      if (selectedSummaryValueOe === "yes") {
        const hidetabSeDiv = document.getElementById('Certificationdev');
        hidetabSeDiv.classList.remove("hidden");
      }
    }
  }
  else {
    // Hide additional divs if they are associated with other tabs
    const additionalDivs = document.querySelectorAll('.additional-divs');
    additionalDivs.forEach(div => {
      div.classList.add("hidden");
    });
  }

  if (targetContentId === '#summary') {
    this.saveBtnTarget.classList.add("hidden");
  } else {
    this.saveBtnTarget.classList.remove("hidden");
  }
}

dependentSelectionOu() {
    const selectedSummarySelect = $(this.selectedSummaryvalueTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('hidetab');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }



  dependentSelectionSe() {
    const selectedSummarySelectSe = $(this.selectedSummaryvalueSeTarget);
    const selectedSummaryValueSe = selectedSummarySelectSe.val();
    const secondSummarySe = document.getElementById('hidetabSe');
  
    if (selectedSummaryValueSe === "yes") {
      secondSummarySe.classList.remove('hidden');
    } else {
      secondSummarySe.classList.add('hidden');
    }
  }

  dependentSelectionExpectedOu() {
    const selectedSummarySelectExpectedOu = $(this.selectedSummaryvalueExpectedOuTarget);
    const selectedSummaryValueExpectedOu = selectedSummarySelectExpectedOu.val();
    const secondSummaryExpectedOu = document.getElementById('hidetabExpectedOu');
  
   
    if (selectedSummaryValueExpectedOu === "yes") {
      secondSummaryExpectedOu.classList.remove('hidden');
    } else {
      secondSummaryExpectedOu.classList.add('hidden');
    }
  }

  dependentSelectionExpectedOe() {
  
    const selectedSummarySelectExpectedOe = $(this.selectedSummaryvalueExpectedOeTarget);
    const selectedSummaryValueExpectedOe = selectedSummarySelectExpectedOe.val();
    const secondSummaryExpectedOe = document.getElementById('hidetabExpectedOe');

    if (selectedSummaryValueExpectedOe === "yes") {
      secondSummaryExpectedOe.classList.remove('hidden');
    } else {
      secondSummaryExpectedOe.classList.add('hidden');
    }
  }


  GradingSelectionOu() {
    const selectedSummarySelect = $(this.selectedGradingvalueOuTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummaryExpectedOe = document.getElementById('hideGradingOu');
  
    if (selectedSummaryValue === "yes") {
      secondSummaryExpectedOe.classList.remove('hidden');
    } else {
      secondSummaryExpectedOe.classList.add('hidden');
    }
  }
  GradingSelectionSe() {
    const selectedSummarySelect = $(this.selectedGradingvalueSeTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('hideGradingSe');
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  dependentSelectionOeNo() {
    const selectedSummarySelect = $(this.selectedSummaryvalueExpectedOuNoTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    console.log('ou: ',selectedSummaryValue);
    const secondSummary = document.getElementById('hidetabExpectedOuNo');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }

  dependentSelectionSeNo() {
    const selectedSummarySelect = $(this.selectedSummaryvalueExpectedSeNoTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    console.log('se: ',selectedSummaryValue);
    const secondSummary = document.getElementById('hidetabExpectedSeNo');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }

  dependentSelectionNoDual() {
    const selectedSummarySelect = $(this.selectedSummaryvalueExpectedNoDualTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('hidetabExpectedNoDual');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  dependentSelectionNoSingle() {
    const selectedSummarySelect = $(this.selectedSummaryvalueExpectedNoSingleTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('hidetabExpectedNoSingle');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  dependentSelectionEligibilityOu() {
    const selectedSummarySelect = $(this.selectedEligibilityOuTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('eligibilityOuDev');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  dependentSelectionEligibilitySe() {
    const selectedSummarySelect = $(this.selectedEligibilitySeTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('eligibilitySeDev');
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  dependentSelectionCertification() {
    const selectedSummarySelect = $(this.selectedCertificateTarget);
    const selectedSummaryValue = selectedSummarySelect.val(); // Get the selected value
    const secondSummary = document.getElementById('Certificationdev');
  
    if (selectedSummaryValue === "yes") {
      secondSummary.classList.remove('hidden');
    } else {
      secondSummary.classList.add('hidden');
    }
  }
  
  toggleActive(event) {
    this.linkTargets.forEach(link => {
      link.classList.remove("border-2", "border-blue-500");
    });
    event.currentTarget.classList.add("border-2", "border-blue-500");
  }  
}