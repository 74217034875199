import { Controller } from "stimulus";
import $ from 'jquery';
require("select2/dist/css/select2");
require("select2-bootstrap-theme/dist/select2-bootstrap");

export default class extends Controller {
    static targets = ['selectedformtype','selectedModalityValue']

    connect() {
        $('.visit-search').select2();
        $('.select2-container--default .select2-selection--multiple').css({
            'min-height': '42px',
            'border-color': '#cbd5e0'
        });
    }
}