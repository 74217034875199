import { Controller } from 'stimulus';
import { Formio } from 'formiojs';
import CornerstoneCommonFunctions from '../src/CornerstoneCommonFunctions.js';
import BaseFileModule from './shared_modules/base_file_module.js';
import FormStateModule from './shared_modules/form_state_module.js';
import NotificationModule from './shared_modules/notification_module.js';
import FormSubmissionModule from './shared_modules/form_submission_module.js';

export default class extends Controller {
    //imageProperties = [];
    static targets = ['form', 'cornerstoneViewer', 'imageTag', 'imageProperties', 'previewLaterality', 'isFinal', 'submit', 'update', 'releaseEye', 'eyeReason', 'eyeComment', 'menu', 'menuIcon', 'formSection', 'viewer', 'viewerIcon', 'questionArea', 'gradability', 'attachments', "tab", "content", 'loadingImage']

    async initialize() {

        this.qcSaveUrl = document.location.origin + this.data.get('updatePath') + '.json';
        this.finalImageProperty = JSON.parse(this.data.get('finalImageProperty'));
        this.humanResponse = JSON.parse(this.data.get('humanResponseData'));
        this.qcFormElem = this.formTarget;
        this.imagesMenu = this.menuTarget;
        this.viewer = this.viewerTarget;
        this.cornerstoneViewer = this.cornerstoneViewerTarget;
        this.imagePropertiesElem = this.imagePropertiesTarget;
        this.isFinal = this.isFinalTarget
        this.qcFormResponses = JSON.parse(this.data.get('qcResponses'));
        const imageKeys = this.data.get('imageKeys');
        if (imageKeys) {
            // Split the string by commas and remove any leading/trailing spaces
            this.images = imageKeys.split(',').map(key => key.trim());
        } else {
            // If imageKeys is not provided, set this.images to an empty array
            this.images = [];
        }
        this.saveHumanResponseUrl = document.location.origin + this.data.get('humanResponse') + '.json';
        this.releaseEye = this.releaseEyeTarget.querySelector('input[name="release_eye"]:checked');
        this.imageProperties = (this.imagePropertiesElem.value != "null") ? JSON.parse(this.imagePropertiesElem.value) : [];
        this.qcFormVersionId = this.data.get('versionId');
        this.qcFormUserId = this.data.get('userId');
        this.stackId = this.data.get('stackId');
        this.imageTag = this.imageTagTarget;
        this.qcFormImageViewer = this.data.get('imageViewer');
        this.qcFormGradingForm = this.data.get('gradingForm');
        this.qcFormStudyEye = this.data.get('studyEye');
        this.qcFormSchema = JSON.parse(this.data.get('qcForm'));
        this.aiResponses = JSON.parse(this.data.get('aiResponses'));
        this.qcFormCheckEye = this.data.get('checkEye');
        if (this.qcFormImageViewer === 'true') {
            this.CornerstoneCommonFunctions = new CornerstoneCommonFunctions(this.cornerstoneViewer, this.imageTagTarget);
            this.CornerstoneCommonFunctions.enableCornerstoneElement();
            this.CornerstoneCommonFunctions.imageLoader(this.url);
        }
        // get ocap  photographers
        this.ocapPhotographers = JSON.parse(this.data.get('ocapPhotographers'));
        const photographers = this.ocapPhotographers.data != null ? this.ocapPhotographers.data.data : [];
        this.ocapDevices = JSON.parse(this.data.get('ocapDevices'));
        const devices = this.ocapDevices.data != null ? this.ocapDevices.data.data : [];
        this.appendOcapPhotographers(photographers, devices);
        if (this.imageProperties.length != 0) {
            this.showLaterality();
        }
        this.qcForm = await Formio.createForm(this.qcFormElem, this.qcFormSchema, {
            noAlerts: true
        });
        // set the draft responses if any
        this.qcForm.submission = this.qcFormResponses;
        this.formSaveDraftStatus = false;
        // show hide reason and comments for study eye in QC form
        if (this.releaseEye !== null) {
            this.showHideStudyEyeDependency();
        }
        // to append images with Questions 
        const displayImagesForQuestions = (formComponents, qcFormResponses, ImagesArray) => {
            // Split the string into an array of URLs using a comma as the separator
            const imagesArray = ImagesArray.split('<==>').map(url => url.trim());
            // Iterate through the form components
            FormioUtils.eachComponent(formComponents, component => {
                // Check if the component key is in qcFormResponses
                if (component.key in qcFormResponses.data) {
                    const questionKey = component.key;
                    const verified_key = questionKey + "_sdv_verified";
                    const comments_key = questionKey + "_sdv_comments";
                    if (component && component.element) {
                        if (qcFormResponses.sdv) {
                            if (verified_key in qcFormResponses.sdv) {
                                const check_sdv = qcFormResponses.sdv[verified_key];
                                const sdv_comments = qcFormResponses.sdv[comments_key];
                                this.appendSdvWithQuestionTitles(component, check_sdv, sdv_comments);
                            }
                        }
                        if (qcFormResponses && qcFormResponses.files && questionKey in qcFormResponses.files) {
                            const imageUrls = qcFormResponses.files[questionKey];
                            this.appendImageWithQuestion(component, imageUrls, imagesArray)
                        }
                    }
                }
            });
        }
        // Call the function with your data
        Formio.createForm(this.qcFormElem, 'https://examples.form.io/example').then((form) => {
            this.qcForm.on("render", () => {
                this.display_ai_answers();
                if (Object.keys(this.qcFormResponses).length > 0) {
                    displayImagesForQuestions(this.qcForm.components, this.qcFormResponses, this.data.get('attachments'));
                }
            });
            this.qcForm.redraw();
            // Reapply functionalities after redraw
            this.qcForm.on("redraw", () => {
                this.display_ai_answers();
            });

            if (this.qcForm.disabled) {
                const dropzoneElements = document.getElementsByClassName('dropzone');
                for (const element of dropzoneElements) {
                    element.classList.add('hidden');
                }
            }
        });
        // end
        // Initialize shared modules
        this.baseFile = new BaseFileModule(this.element);
        this.notifications = new NotificationModule(this.hasLoadingImageTarget ? this.loadingImageTarget : null);
        this.formState = new FormStateModule(this.qcForm, this.formTarget, this.hasSubmitTarget ? this.submitTarget : null, this.hasUpdateTarget ? this.updateTarget : null);
        this.formSubmissionModule = new FormSubmissionModule({
            formSaveUrl: document.location.origin + this.data.get('saveUrl') + '.json',
            formVersionId: this.data.get('versionId'),
            formUserId: this.data.get('userId'),
            formStackId: this.data.has('stackId') ? this.data.get('stackId') : null,
            notifications: this.notifications,
            formSaveDraftStatus: false,
            stackIsFinalUrl: document.location.origin + this.data.get('updateIsFinalPath') + '.json',
            images: this.images
        });
        // Set the form in modules
        this.notifications.hideLoader();
        this.baseFile.setForm(this.qcForm);
        if (this.isFinal.checked) {
            this.formState.disableForm();
        } else {
            this.formState.enableForm();
        }
    }

    display_ai_answers() {
        if (!this.aiResponses?.length || !this.qcForm.components) return;
        this.aiResponses.forEach(scansObject => {
            const keys = Object.keys(scansObject);
            this.qcForm.components.forEach(c => {
                const scanType = c.component.attributes['data-ai'];
                if (!scanType) return;
                this.UpdateSectionLabel(c, scanType, keys, this.aiResponses);
                keys.forEach(scanKey => {
                    const scans = scansObject[scanKey];
                    if (!scans || !scans.length) return;
                    c.components.forEach(question_component => {
                        if (question_component && question_component.component && question_component.element) {
                            const element = question_component.element;
                            if (!element.classList.contains('formio-hidden')) {
                                const dataAi = question_component.component.attributes && question_component.component.attributes['data-ai'];
                                const suggestedAnswers = [];
                                const AIAnswersDiv = element.querySelector('.ai-answers-div') || document.createElement('div');
                                scans.forEach(scan => {
                                    if (dataAi && scan && scan[dataAi]) {
                                        const value = scan[dataAi];
                                        suggestedAnswers.push(`${suggestedAnswers.length + 1}. ${value}`);
                                    }
                                });
                                if (suggestedAnswers.length > 0) {
                                    AIAnswersDiv.classList.add('ai-answers-div', 'font-semibold', 'mt-1', 'border', 'rounded', 'border-green-400', 'p-1');
                                    const answersSpan = document.createElement('span');
                                    answersSpan.textContent = suggestedAnswers.join(' | ');
                                    var spanExists = false;
                                    for (var i = 0; i < AIAnswersDiv.childNodes.length; i++) {
                                        if (AIAnswersDiv.childNodes[i].tagName === 'SPAN') {
                                            spanExists = true;
                                            break;
                                        }
                                    }
                                    if (!spanExists) {
                                        AIAnswersDiv.appendChild(answersSpan);
                                    }
                                    element.append(AIAnswersDiv);
                                }
                            }
                        }
                    });
                });
            });
        });
    }

    UpdateSectionLabel(c, scanType, scansKeys, AiResponses) {
        let label = c.label; // Get the original label
        const componentInfo = c.components.map(component => {
            return {
                key: component.component.key,
                type: component.component.type,
                AIkey: component.component.attributes['data-ai'] || ''  // Default to empty string if undefined,
                // Add more properties as needed
            };
        });
        scansKeys.forEach(key => {
            // Check if the key matches the scanType
            if (key === scanType) {
                // Find the corresponding AI response
                const flattenedAiResponses = AiResponses.flat();
                const response = flattenedAiResponses.find(obj => obj.hasOwnProperty(scanType));
                if (response) {
                    const scanObjects = response[scanType];
                    if (scanObjects && scanObjects.length > 0) {
                        const scanLabels = scanObjects.map((scan, index) => {
                            // const examDate = scan[`${scanType}_ExamDate`] || '';
                            let studyDate = response['study_and_subject_info'][0]['Study_Date'] || '';
                            let [year, month, day] = studyDate.split('-');
                            month = month.padStart(2, '0');
                            day = day.padStart(2, '0');
                            // Combine them into the desired format
                            let examDate = `${year}${month}${day}`;
                            const acquisitionTime = scan[`${scanType}_AcquisitionTime`] || '';
                            const formattedTime = acquisitionTime ? `${acquisitionTime.slice(0, 2)}:${acquisitionTime.slice(2, 4)}:${acquisitionTime.slice(0, 2)}` : '';
                            const clickableDate = document.createElement('a');
                            // Create the span element with bold text
                            const span = document.createElement('span');
                            span.classList.add('font-bold');
                            span.textContent = `Scan ${index + 1}`;
                            // Append the span to the a tag
                            clickableDate.appendChild(span);
                            // Add the remaining text to the a tag
                            clickableDate.appendChild(document.createTextNode(` (${examDate} at ${formattedTime} UTC-05)`));
                            clickableDate.setAttribute('data-action', 'click->qc-form#appendAIAnswers');
                            clickableDate.setAttribute('data-scan-type', scanType);
                            clickableDate.setAttribute('data-component', JSON.stringify(componentInfo));
                            clickableDate.setAttribute('data-scan', JSON.stringify(scan));
                            clickableDate.classList.add('clickable-date');
                            clickableDate.style.cursor = 'pointer';
                            return clickableDate.outerHTML;
                        });

                        label += '<br>' + scanLabels.join(' | ');
                        const sectionClassName = `formio-component-${c.key}`;
                        const sectionElement = document.querySelector(`.${sectionClassName}`);
                        if (sectionElement) {
                            const cardTitleElement = sectionElement.querySelector('.card-title');
                            if (cardTitleElement) {
                                cardTitleElement.innerHTML = label;
                            }
                        }
                    }
                }
            }
        });
    }

    autoAppendAIAnswers() {
        const allSections = document.querySelectorAll('a[data-action="click->qc-form#appendAIAnswers"]');

        // Define a function to click on sections sequentially
        const clickNextSection = (index) => {
            if (index < allSections.length) {
                // Get the parent span of the current link
                const parentSpan = allSections[index].closest('span.mb-0.card-title.text-light');
                // Check if the parent span contains only one link
                if (parentSpan && parentSpan.querySelectorAll('a').length === 1) {
                    // Simulate a click event
                    allSections[index].click();

                    // Wait for a brief moment (e.g., 500 milliseconds) before clicking the next section
                    setTimeout(() => {
                        clickNextSection(index + 1); // Click the next section
                    }, 500); // Adjust the timeout duration as needed
                } else {
                    // If there's more than one link in the parent span, skip clicking and move to the next section
                    clickNextSection(index + 1);
                }
            }
        };

        // Start clicking on sections after a slight delay
        setTimeout(() => {
            clickNextSection(0);
        }, 100); // Adjust the delay duration as needed
    }

    appendAIAnswers(e) {
        const formComponentsAttribute = e.target.getAttribute('data-component');
        const AIScanAttribute = e.target.getAttribute('data-scan');
        const Section = e.target.getAttribute('data-scan-type');

        if (formComponentsAttribute && AIScanAttribute) {
            // Parse the attribute values as arrays or objects
            const formComponents = JSON.parse(formComponentsAttribute);
            const AIScan = JSON.parse(AIScanAttribute); // Parse AIScan as JSON

            if (Array.isArray(formComponents)) {
                formComponents.forEach(component => {
                    if (component && component.AIkey !== undefined) {
                        this.selectRadioByAttribute(this.qcFormSchema, component.AIkey, AIScan[component.AIkey]);
                    }
                });
            } else {
                console.error("Data component attribute is not an array.");
            }
        }
    }

    selectRadioByAttribute(qcFormSchema, attributeValue, answer) {
        // Parse the qcFormSchema if it's a string
        const formSchema = typeof qcFormSchema === 'string' ? JSON.parse(qcFormSchema) : qcFormSchema;

        // Iterate through the components to find the one with the specified attribute value
        for (const panel of formSchema.components) {
            // Check if the panel has components
            if (panel.components && Array.isArray(panel.components)) {
                for (const component of panel.components) {

                    // Check if the component has the specified attribute value
                    if (component.attributes && component.attributes['data-ai'] === attributeValue && component.type === 'radio') {
                        // Find the radio option with the specified answer
                        if (answer) {
                            const selectedOption = component.values.find(option => option.label.toLowerCase() === answer.toLowerCase());
                            if (selectedOption) {
                                // Set the radio button value
                                component.defaultValue = selectedOption.value;
                                // Find the parent div of the radio option and add the 'radio-selected' class to it
                                const parentDivs = document.querySelectorAll(`.formio-component-${component.key} .form-check`);
                                for (const div of parentDivs) {
                                    if (div.querySelector(`input[value="${selectedOption.value}"]`)) {
                                        div.classList.add('radio-selected');

                                        // Simulate a click on the selected radio input
                                        const radioInput = div.querySelector(`input[value="${selectedOption.value}"]`);
                                        if (radioInput) {
                                            radioInput.click();
                                        }
                                    } else {
                                        div.classList.remove('radio-selected');
                                    }
                                }

                                return; // Exit the function once the component is found and updated
                            }
                        }
                    }
                }
            }
        }
    }

    appendSdvWithQuestionTitles(component, check_sdv, sdv_comments) {

        if (check_sdv === 'true') {
            // Create a span element for the SVG icon with the tooltip
            const svgDive = document.createElement('span');
            svgDive.classList.add('has-tooltip');
            svgDive.innerHTML = `
          <?xml version="1.0" encoding="iso-8859-1"?>
            <!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
            <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
            <svg fill="#007BFF" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" 
                width="16px" height="16px" viewBox="0 0 509.604 509.604"
                xml:space="preserve" style ="margin-top:3px">
                <g>
                    <g>
                        <path d="M34.262,333.282c8.119,6.75,14.793,15.223,14.143,20.988c-0.382,3.443-0.593,6.943-0.593,10.5
                            c0,52.393,41.3,94.861,92.24,94.861c6.292,0,12.431-0.65,18.37-1.885c10.002-2.074,21.812,1.941,28.888,9.793
                            c16.82,18.646,40.803,30.342,67.492,30.342c28.19,0,53.426-13.016,70.342-33.518c6.723-8.146,18.103-11.533,28.22-8.5
                            c8.166,2.447,16.811,3.768,25.751,3.768c50.939,0,92.24-42.477,92.24-94.861c0-5.861-0.535-11.59-1.549-17.145
                            c-1.712-9.371,2.85-21.047,10.471-28.363c18.025-17.289,29.328-41.883,29.328-69.242c0-29.787-13.368-56.323-34.263-73.698
                            c-8.118-6.751-14.793-15.224-14.143-20.99c0.383-3.442,0.593-6.942,0.593-10.5c0-52.393-41.301-94.86-92.24-94.86
                            c-6.292,0-12.431,0.65-18.369,1.884c-10.002,2.075-21.812-1.941-28.889-9.792c-16.82-18.647-40.803-30.342-67.492-30.342
                            c-26.688,0-50.671,11.695-67.492,30.342c-7.076,7.841-18.886,11.867-28.888,9.792c-5.938-1.234-12.078-1.884-18.37-1.884
                            c-50.939,0-92.24,42.477-92.24,94.86c0,5.049,0.392,10.002,1.147,14.832c1.262,8.128-4.447,18.149-12.747,24.681
                            C14.219,201.663,0,228.887,0,259.583C0,289.37,13.368,315.907,34.262,333.282z M131.475,263.016
                            c2.046-3.625,7.268-3.672,12.049,0.479l48.119,33.918c2.61,1.588,5.106,2.4,7.506,2.4c4.963,0,8.893-3.576,12.689-7.02
                            l153.985-154.138c9.629-10.471,18.99-14.162,25.102-10.146c2.82,1.855,4.646,4.647,5.135,7.87
                            c0.583,3.825-0.756,7.946-3.768,11.599l-185.149,224.91c-2.687,3.26-6.11,5.059-9.629,5.059c-4.179,0-7.965-2.516-10.404-6.895
                            l-54.344-97.969C130.519,269.422,130.021,265.618,131.475,263.016z"/>
                    </g>
                </g>
            </svg>     
            <span class="tooltip bottom-2 w-min">${sdv_comments}</span>
          `;
            // Get the label element within component.element
            const labelElement = component.element.querySelector('label');
            labelElement.classList.add('flex', 'gap-2');
            // Concatenate component.label and svgDive and set it as the innerHTML of labelElement
            labelElement.innerHTML = component.label + svgDive.outerHTML;
        }
    }

    appendImageWithQuestion(component, imageUrls, imagesArray) {
        // Create a div to hold the images
        const imageDiv = document.createElement('div');
        imageDiv.classList.add('mt-1', 'border', 'rounded', 'border-green-300', 'flex', 'gap-2');
        // Track added images to avoid duplicates
        const addedImages = new Set();

        // Iterate through the image URLs and create image elements
        for (const imageUrl of imageUrls) {
            for (let i = 0; i < imagesArray.length; i++) {
                let imageUrlParts = imagesArray[i].split('/');
                let imageName = imageUrlParts[imageUrlParts.length - 1];
                // Decode the URL containing %20
                let decodedImageUrl = decodeURIComponent(imageName);
                if (imageUrl == decodedImageUrl && !addedImages.has(decodedImageUrl)) {
                    const imageElement = document.createElement('img');
                    imageElement.setAttribute('src', imagesArray[i]);
                    imageElement.setAttribute('alt', 'Attachment Image');
                    imageElement.setAttribute('width', '100');
                    imageElement.setAttribute('height', '100');
                    imageElement.style.cursor = 'pointer';
                    imageElement.addEventListener('click', function () {
                        const lightboxImage = document.getElementById('lightbox-image');
                        lightboxImage.src = imagesArray[i];
                        const lightboxContainer = document.getElementById('lightbox-container');
                        lightboxContainer.style.display = 'block';
                    });
                    const lightboxContainer = document.getElementById('lightbox-container');
                    lightboxContainer.addEventListener('click', function () {
                        lightboxContainer.style.display = 'none';
                    });
                    // Append the image to the div
                    imageDiv.appendChild(imageElement);
                    // Mark the image as added
                    addedImages.add(decodedImageUrl);
                }
            }
        }
        // Append the image div to the question component's element
        if (imageDiv.innerHTML.trim() !== "") {
            component.element.appendChild(imageDiv);
        }
    }

    get url() {
        return this.data.get('url');
    }

    imageSelected(event) {

        let imageProperty = this.imageProperties.filter(p => p.blob_id == event.target.dataset.id);
        let finalImageProperty = this.finalImageProperty.filter(p => p.blob_id == event.target.dataset.blobId);
        let humanResponse = this.humanResponse.filter(p => p.blob_id == event.target.dataset.blobId);
        if (imageProperty.length > 0 && this.previewLateralityTarget.innerHTML) {
            this.previewLateralityTarget.innerHTML = imageProperty[0].laterality
        }
        else {
            this.previewLateralityTarget.innerHTML = ""
        }
        this.changeImage(event);
        if (finalImageProperty.length > 0) {
            this.appendAIResponses(finalImageProperty);
        }
        this.appendHumanResponses(humanResponse);
        let study_blob_id = document.getElementById('study_blob_id');
        study_blob_id.value = event.target.dataset.blobId;
    }

    appendHumanResponses(humanResponse) {
        let artifact_human_response = document.getElementById('artifact_human_response');
        let exposure_human_response = document.getElementById('exposure_human_response');
        let focus_human_response = document.getElementById('focus_human_response');
        let grade_human_response = document.getElementById('grade_human_response');
        let magnification_human_response = document.getElementById('magnification_human_response');
        let laterality_human_response = document.getElementById('laterality_human_response');
        let field_human_response = document.getElementById('field_human_response');
        let type_human_response = document.getElementById('type_human_response');
        if (humanResponse.length > 0) {
            artifact_human_response.innerHTML = ' ( ' + (humanResponse[0]['artifacts'] || '') + ' )';
            exposure_human_response.innerHTML = ' ( ' + (humanResponse[0]['exposure'] || '') + ' )';
            focus_human_response.innerHTML = ' ( ' + (humanResponse[0]['focus'] || '') + ' )';
            grade_human_response.innerHTML = ' ( ' + (humanResponse[0]['grade'] || '') + ' )';
            magnification_human_response.innerHTML = ' ( ' + (humanResponse[0]['magnification'] || '') + ' )';
            laterality_human_response.innerHTML = ' ( ' + (humanResponse[0]['laterality'] || '') + ' )';
            field_human_response.innerHTML = ' ( ' + (humanResponse[0]['field'] || '') + ' )';
            type_human_response.innerHTML = ' ( ' + (humanResponse[0]['type'] || '') + ' )';
        } else {
            artifact_human_response.innerHTML = '';
            exposure_human_response.innerHTML = '';
            focus_human_response.innerHTML = '';
            grade_human_response.innerHTML = '';
            magnification_human_response.innerHTML = '';
            laterality_human_response.innerHTML = '';
            field_human_response.innerHTML = '';
            type_human_response.innerHTML = '';
        }
    }

    appendAIResponses(finalproperty) {
        let artifacts = document.getElementById('study_artifacts');
        let exposure = document.getElementById('study_exposure');
        let focus = document.getElementById('study_focus');
        let grade = document.getElementById('study_grade');
        let magnification = document.getElementById('study_magnification');
        let laterality = document.getElementById('study_laterality');
        let field = document.getElementById('study_field');
        let type = document.getElementById('study_type');

        if (finalproperty.length > 0) {
            artifacts.value = finalproperty[0]['artifacts'] || '';
            exposure.value = finalproperty[0]['exposure'] || '';
            focus.value = finalproperty[0]['focus'] || '';
            grade.value = finalproperty[0]['grade'] || '';
            magnification.value = finalproperty[0]['magnification'] || '';
            laterality.value = finalproperty[0]['laterality'] || '';
            field.value = finalproperty[0]['field'] || '';
            type.value = finalproperty[0]['type'] || '';
        } else {
            artifacts.value = '';
            exposure.value = '';
            focus.value = '';
            grade.value = '';
            magnification.value = '';
            laterality.value = '';
            field.value = '';
            type.value = '';
        }
    }

    saveResponse() {
        let study_blob_id = document.getElementById('study_blob_id');
        let artifacts = document.getElementById('study_artifacts');
        let exposure = document.getElementById('study_exposure');
        let focus = document.getElementById('study_focus');
        let grade = document.getElementById('study_grade');
        let magnification = document.getElementById('study_magnification');
        let laterality = document.getElementById('study_laterality');
        let field = document.getElementById('study_field');
        let type = document.getElementById('study_type');

        if (!study_blob_id.value) {
            alert("Please Select an Image");
        } else if (!artifacts.value && !exposure.value && !focus.value && !grade.value && !magnification.value && !laterality.value && !field.value && !type.value) {
            alert("Please enter at least one input before saving the response.");
        } else {
            fetch(this.saveHumanResponseUrl, {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8',
                    'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
                },
                method: 'PATCH',
                body: JSON.stringify({
                    stack_id: this.stackId,
                    study_blob_id: study_blob_id.value,
                    artifacts: artifacts.value,
                    exposure: exposure.value,
                    focus: focus.value,
                    grade: grade.value,
                    magnification: magnification.value,
                    laterality: laterality.value,
                    field: field.value,
                    type: type.value
                })
            }).then(response => response.json())
                .then(data => {
                    alert(data.message);
                    let blob_id = data.blob_id;
                    let humanResponses = data.human_responses;
                    let filteredResponses = humanResponses.filter(response => response.blob_id === blob_id);
                    this.appendHumanResponses(filteredResponses);
                });
        }
    }

    changeImage(e, elem) {
        let target;
        if (e) target = e.target;
        else target = elem;
        // load new image
        this.CornerstoneCommonFunctions.imageLoader(target.dataset.url);
        // if the call came from click adjust the currentImage index
        if (e) {
            this.currentImage = target.dataset.idx;
        }
        // remove all active borders
        const allImageBoxes = document.querySelectorAll('.image-box');
        allImageBoxes.forEach(i => i.classList.remove('border-primary-500'));

        // active border around current image
        target.parentNode.classList.add('border-primary-500');
    }

    assignLaterality() {
        const elements = document.getElementsByClassName("image_checkbox");
        const laterality = document.getElementById("image_laterality");
        // check if any image selected for laterality
        if (!this.checkSelectedImages()) {
            return;
        }

        if (laterality.value) {
            const lateralityValue = laterality.value;
            const oppositeLateralityValue = lateralityValue === "OD" ? "OS" : "OD";

            Array.from(elements).forEach((element, index) => {
                if (element.checked) {
                    this.imageProperties[index] = {
                        blob_id: element.dataset.id,
                        laterality: lateralityValue,
                    };
                } else {
                    this.imageProperties[index] = {
                        blob_id: element.dataset.id,
                        laterality: oppositeLateralityValue,
                    };
                }
            });

            this.saveLaterality(this.imageProperties);
        }
    }

    checkSelectedImages() {
        if (document.querySelector('.image_checkbox:checked') === null) {
            alert("No images selected for literality.")
            return false;
        }
        if (document.getElementById("image_laterality").value === "") {
            alert("Select valid option for literality.")
            return false;
        }
        return true;
    }

    checkImagesLiterality() {
        if (this.imageProperties.length > 0) {
            return true;
        }
        return false;
    }

    checkLiteralityWithReleaseEye() {
        var sameRelaseAndLiterality = false;
        var allLiteralityImages = document.querySelectorAll('.img_literality_text');
        allLiteralityImages.forEach(i => {
            if (this.releaseEyeTarget.querySelector('input[name="release_eye"]:checked').value == "OD" && i.innerHTML == "Laterality : OD") {
                sameRelaseAndLiterality = true;
            }
            if (this.releaseEyeTarget.querySelector('input[name="release_eye"]:checked').value == "OS" && i.innerHTML == "Laterality : OS") {
                sameRelaseAndLiterality = true;
            }
        }); // loop ends
        return sameRelaseAndLiterality;
    }

    saveLaterality(body) {

        fetch(this.qcSaveUrl, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
            },
            method: 'PATCH',
            body: JSON.stringify({
                image_properties: body
            })
        }).then(response => {

            this.showLaterality();
            this.clearCheckboxes();

        });
    }

    showLaterality() {
        this.imageProperties.forEach(img => {
            document.getElementById(img.blob_id).innerHTML = `Laterality : ${img.laterality}`
        })
    }

    clearCheckboxes() {
        const checkboxes = document.getElementsByClassName("image_checkbox");
        document.getElementById("image_laterality").value = "";
        checkboxes.forEach((checkbox, index) => {
            checkbox.checked = false;
        });
    }

    selectAllChange(event) {
        const checkboxes = document.getElementsByClassName("image_checkbox");
        checkboxes.forEach((checkbox, index) => {
            checkbox.checked = event.target.checked;
        });

        this.imageCheckboxChange();
    }

    selectHalfChange(event) {
        const checkboxes = document.getElementsByClassName("image_checkbox");
        const halfCheckboxes = Math.floor(checkboxes.length / 2);

        checkboxes.forEach((checkbox, index) => {
            if (index < halfCheckboxes) {
                checkbox.checked = event.target.checked;
            }
        });
    }

    imageCheckboxChange() {
        const totalCheckboxes = document.querySelectorAll(".image_checkbox").length;
        const checkedCheckboxes = document.querySelectorAll(".image_checkbox:checked").length;

        // select half
        const selectHalf = document.querySelector(".checkbox_select_half");
        const halfCheckboxes = Math.floor(totalCheckboxes / 2);
        if (checkedCheckboxes >= halfCheckboxes) {
            selectHalf.checked = true;
        } else {
            selectHalf.checked = false;
        }

        // select all
        const selectAll = document.querySelector(".checkbox_select_all");
        if (checkedCheckboxes === totalCheckboxes) {
            selectAll.checked = true;
        } else {
            selectAll.checked = false;
        }
    }

    formSaveDraft(e) {
        this.handleFormCommitAndSubmit(e, 'initiated', true, true);
    }

    formCommit(e) {
        this.handleFormCommitAndSubmit(e, 'completed', true);
    }

    formUpdate(e) {
        this.handleFormCommitAndSubmit(e, 'editing');
    }

    // formio functions
    handleFormCommitAndSubmit(e, status, shouldSubmit = false, draftStatus = false) {
        e.preventDefault();

        // Validate the form before submission
        this.qcForm.submit().then((submission) => {
            // If the form is valid, proceed with commit and submission
            this.formSubmissionModule.formCommit(
                e,
                this.isFinal.checked,
                '.buttonclick',
                this.releaseEyeTarget,
                this.gradabilityTarget,
                this.eyeReasonTarget.querySelector('input'),
                this.eyeCommentTarget.querySelector('input'),
                this.qcFormImageViewer,
                this.checkImagesLiterality.bind(this),
                this.checkLiteralityWithReleaseEye.bind(this),
                status,
                draftStatus
            ).then((commitResult) => {
                // If formCommit returns false, stop further processing
                if (!commitResult) {
                    console.log('Form commit returned false, submission aborted.');
                    return; // Exit the function early
                }

                // Handle the form submission after commit is successful
                if (shouldSubmit) {

                    this.formSubmissionModule.collectImagesFromSelector('.attached_images', this.images);
                    this.formSubmissionModule.submitForm(
                        submission,
                        'PATCH',
                        null,
                        null,
                        draftStatus,
                        'qc_form',
                        status
                    );
                }
            }).catch(error => {
                console.error('Commit failed:', error);
            });

        }).catch((error) => {
            // Form validation failed
            console.error('Form validation failed:', error);
            this.notifications.hideLoader();
        });
    }



    showHideStudyEyeDependency() {
        const release_eye = this.releaseEyeTarget.querySelector('input[name="release_eye"]:checked').value
        if (release_eye == 'NA') {
            this.eyeReasonTarget.classList.remove('hidden');
            this.eyeCommentTarget.classList.add('hidden');
        } else {
            this.eyeReasonTarget.classList.add('hidden');
            this.eyeCommentTarget.classList.remove('hidden');
        }
    }

    appendOcapPhotographers(photographers, devices) {
        // panel
        Object.keys(this.qcFormSchema.components).forEach((p_key, p_index) => {
            // component 
            Object.keys(this.qcFormSchema.components[p_index]['components']).forEach((c_key, c_index) => {
                // photographers
                if (this.qcFormSchema.components[p_index]['components'][c_index].key == "ocap-photographers") {
                    Object.assign(this.qcFormSchema.components[p_index]['components'][c_index], {
                        "data": {
                            "values": photographers,
                            "json": "",
                            "url": "",
                            "resource": "",
                            "custom": ""
                        }
                    });
                }
                // devices
                if (this.qcFormSchema.components[p_index]['components'][c_index].key == "ocap-devices") {
                    Object.assign(this.qcFormSchema.components[p_index]['components'][c_index], {
                        "data": {
                            "values": devices,
                            "json": "",
                            "url": "",
                            "resource": "",
                            "custom": ""
                        }
                    });
                }
            }); // component foreach
        }); // panel foreach
    }

    toggleSubmitArea() {
        const staticPanel = document.getElementById('expend_colapse');
        const isHidden = this.questionAreaTarget.classList.toggle('hidden');

        staticPanel.classList.toggle('fa-minus-square-o', !isHidden);
        staticPanel.classList.toggle('fa-plus-square-o', isHidden);
    }

    showTab(event) {
        event.preventDefault()
        const clickedTab = event.currentTarget
        const targetContent = document.querySelector(clickedTab.getAttribute("href"))
        // Hide all tab contents
        this.contentTargets.forEach(content => content.classList.add("hidden"))
        // Show the target tab content
        targetContent.classList.remove("hidden")
    }

}